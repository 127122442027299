import React from 'react';

const Motto = () => {
    return (
        <div id="our-motto" className="col-lg-6 col-sm-12">
            <div className="card personalize shadow-sm" style={{ minHeight: '160px'}}>
                <div className="card-header">
                    <h3>Our Motto</h3>
                </div>
                <div className="card-body">
                    <p className="card-text">"Neighbors working together to enhance our community, state and nation."&nbsp;</p>
                </div>
            </div>
        </div>
    );
}

export default Motto;