import React from 'react';

const PrintableCovidInfoLinks = () => {
    return (
        <>
            <h5 className="mt-3">COVID-19 Printable Information & Links</h5>
            <ul>
                <li><i className="fa-li fa fa-file-pdf-o" aria-hidden="true"></i><a href={process.env.PUBLIC_URL + `/files/covid-19/COVID-19-Poster.pdf`} target="_blank" rel="noopener noreferrer">COVID-19 Information Poster</a></li>
                <li><i className="fa-li fa fa-file-pdf-o" aria-hidden="true"></i><a href={process.env.PUBLIC_URL + `/files/covid-19/CDC-COVID-19-factsheet-English.pdf`} target="_blank" rel="noopener noreferrer">CDC Fact Sheet - English</a></li>
                <li><i className="fa-li fa fa-file-pdf-o" aria-hidden="true"></i><a href={process.env.PUBLIC_URL + `/files/covid-19/CDC-COVID-19-factsheet-Spanish.pdf`} target="_blank" rel="noopener noreferrer">CDC Fact Sheet - Spanish</a></li>
                <li><i className="fa-li fa fa-file-pdf-o" aria-hidden="true"></i><a href={process.env.PUBLIC_URL + `/files/covid-19/COVID-19-FLU-Similarities.pdf`} target="_blank" rel="noopener noreferrer">COVID-19 Flu Similarities</a></li>
                <li><i className="fa-li fa fa-file-pdf-o" aria-hidden="true"></i><a href={process.env.PUBLIC_URL + `/files/covid-19/ESD-covid-19-scenarios-and-benefits.pdf`} target="_blank" rel="noopener noreferrer">Employment Security Department COVID-19 Scenarios & Benefits Available</a></li>
                <li><i className="fa-li fa fa-file-pdf-o" aria-hidden="true"></i><a href={process.env.PUBLIC_URL + `/files/covid-19/MC-COVID-19-Fact-Sheet.pdf`} target="_blank" rel="noopener noreferrer">Mason County COVID-19 Fact Sheet</a></li>
                <li><i className="fa-li fa fa-file-pdf-o" aria-hidden="true"></i><a href={process.env.PUBLIC_URL + `/files/covid-19/what-you-need-to-know.pdf`} target="_blank" rel="noopener noreferrer">What you need to know about novel coronavirus</a></li>
                <li><i className="fa-li fa fa-file-pdf-o" aria-hidden="true"></i><a href={process.env.PUBLIC_URL + `/files/covid-19/WFSCOVID_Recommendations.pdf`} target="_blank" rel="noopener noreferrer">Recommendations for Wildfire Smoke and COVID-19</a> </li>
                {/* <li><a href={process.env.PUBLIC_URL + `/files/covid-19/event-cancellations.pdf`} target="_blank" rel="noopener noreferrer">Event Cancellation Guidance from WA State DOH</a></li> */}
                {/* <li><a href={process.env.PUBLIC_URL + `/files/covid-19/FoodWorkerEstablishment.pdf`} target="_blank" rel="noopener noreferrer">DOH Information and Guidelines regarding food establishments</a></li> */}
                {/* <li><a href={process.env.PUBLIC_URL + `/files/covid-19/PublicGatheringsAndEventsGuidance.pdf`} target="_blank" rel="noopener noreferrer">DOH Information and Guidelines regarding gatherings</a></li> */}
                <li><a href="http://www.co.mason.wa.us/COVID-19/printable-information.php" target="_blank" rel="noopener noreferrer">More printable resources and links on the Mason County website</a></li>
                <li><a href="http://www.co.mason.wa.us/COVID-19/index.php#FAQ" target="_blank" rel="noopener noreferrer">More frequently asked questions and responses on the Mason County website</a></li>
            </ul>
        </>
    );
};

export default PrintableCovidInfoLinks;
