import React, { useState } from 'react';

const PressReleaseLink = ({ month, altText, fileName, startIndex, index, totalCount }) => {
    const [isPosExpanded, setIsPosExpanded] = useState({
        'June': false,
        'May': false,
        'April': false,
        'March': false
    });
    
    const arrowUp = (
        <svg className="bi bi-chevron-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd" />
        </svg>
    );
    
    const arrowDown = (
        <svg className="bi bi-chevron-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
        </svg>
    );

    const date = fileName.substring(startIndex, fileName.length - 4);
        return (
            <li key={fileName} className={index > 0 ? `collapse multi-collapse-pos-${month}` : ''}>
                
                <a href={process.env.PUBLIC_URL + `/files/covid-19/positive/2020-${month}/${fileName}`} alt={`${altText} ${date}`} target="_blank" rel="noopener noreferrer">
                    <i className="fa-li fa fa-file-pdf-o" aria-hidden="true"></i>
                    {date}
                </a>
                {index === 0 && totalCount > 1 ?
                    <button
                        id="expand-link"
                        className="btn btn-link mt-n2 ps-1 pt-0 pb-0"
                        data-toggle="collapse"
                        data-target={`.multi-collapse-pos-${month}`}
                        aria-expanded="false"
                        aria-controls="collapse"
                        onClick={() => setIsPosExpanded((prevIsPosExpanded) => ({...prevIsPosExpanded, [month]: !prevIsPosExpanded[month]}))}
                    >
                        { isPosExpanded[month] ? arrowUp : arrowDown }
                    </button> : null}
                </li>
        );
};

export default PressReleaseLink;
