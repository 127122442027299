import React from 'react';
import CallCountDynamic from '../Dynamic/CallCountDynamic';

function CallCount() {
    return (
        <div className="card personalize shadow-sm">
            <div className="card-header">
                <h6 className="card-title">The Grapeview Fire Department responded to:</h6>
            </div>
            <CallCountDynamic />
        </div>
    );
}

export default CallCount;
