import React from "react";
import Email from "../Email";
import ScrollToTopOnMount from "../../ScrollToTopOnMount";
import { Col, Container, Row } from "react-bootstrap";

function EMSLevyRenewal() {
  return (
    <div id="covid19" className="content">
      <div className="container">
        <ScrollToTopOnMount />
        <h1 className="page-heading">APRIL 25, 2023 EMS LEVY RENEWAL</h1>

        <article>
          <Container className="mt-5 mb-5">
            <Row>
              <Col md={1}></Col>
              <Col className="fs-4">
                Mason County Fire District 3 is soliciting interested district
                voters to serve on a <strong>For or Against Committee</strong>{" "}
                to write a statement supporting or opposing the EMS Levy
                Renewal. Please submit your name and name of the committee you
                are interested in serving on to the{" "}
                <strong>District’s Board Secretary</strong> at{" "}
                <Email email="chiefg@wavecable.com" />
                <br /> by <strong>Thursday, February 23, 2023</strong>.
              </Col>
              <Col md={1}></Col>
            </Row>
          </Container>
        </article>
      </div>
    </div>
  );
}

export default EMSLevyRenewal;
