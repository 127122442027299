import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import eventUtil from '../../utils/event';
import moment from "moment";
import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;

const displayMulti = (start, end) => {
    return (
        <><span>{start} - {end}</span><br /></>
    );
};

const displayOne = (event) => {
    const startTime = event && event.start && moment(event.start).format("h:mm a");
    let endTime = event && event.end ? ` - ${moment(event.end).format("h:mm a")}` : null;
    if (event && event.start && event.end) {
        if (moment(event.start).format("h:mm a") === moment(event.end).format("h:mm a")) {
            endTime = null;
        }
    }
    return (
        <>
            <span><strong>Time:</strong></span><br />
            <span>{startTime}{endTime}</span>
            <br />
        </>
    );
};

const EventAgenda = ({ event }) => {
    const venue = eventUtil.mapVenue(event.venue);
    const start = event && event.start;
    const end = event && event.end;
    const isMultiDay = moment(start).format("MM/DD/YYYY") !== moment(end).format("MM/DD/YYYY");
    const formattedStart = moment(start).format("MM/DD/YYYY");
    const formattedEnd = moment(end).format("MM/DD/YYYY");
    let isZoomMeeting = false;
    if (event.website && event.website.length > 0 && event.website.toLowerCase().indexOf('zoom') > -1) {
        isZoomMeeting = true;
    }
    return (
        <span style={{ fontSize: '12px', overflowWrap: 'normal' }}>
            <h6 style={{ color: '#0f2940'}}>{event.title}</h6>
            {(venue && venue.venue) ? (
                    <>
                        <span><strong>Venue:</strong></span><br />
                        <span>{venue.venue.toString()}</span><br />
                        <span>{venue.address}<br />{venue.city ? `${venue.city}, ` : null}{venue.state} {venue.zip}</span><br />
                    </>
            ) : null}
            {isMultiDay ? displayMulti(formattedStart, formattedEnd) : displayOne(event)}
            <span><strong>Category:</strong></span><br />
            <span>{event.categories ? `${eventUtil.mapCategories(event.categories)}` : null}</span><br />
            {event.website ? <span><strong>Website:</strong> <a target="_blank" rel="noopener noreferrer" href={event.website} alt={`${event.title} website`}>{event.website}</a></span> : null}
            {/* {isZoomMeeting ? <span className="ps-3"><i className="fa fa-question-circle small pe-1" aria-hidden="true" /> <Link to="/zoomhelp" className="text-center mt-2">Need help joining Zoom meetings?</Link></span> : null} */}
            <span dangerouslySetInnerHTML={{__html: sanitizer(event.desc)}} />
            {isZoomMeeting ? <div className="mt-2"><i className="fa fa-question-circle small pe-1" aria-hidden="true" /> <Link to="/zoomhelp" className="text-center mt-2">Need help joining Zoom meetings?</Link></div> : null}
        </span>
    );
};

EventAgenda.propTypes = {
    event: PropTypes.object
};

export default EventAgenda;
