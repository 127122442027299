import React from "react";
import MissionStatement from "./MissionStatement";
import Motto from "./Motto";
import CallCount from "./CallCount";
import SocialMedia from "./SocialMedia";
import WelcomeMessage from "./WelcomeMessage";
// import UpcomingEvents from "./UpcomingEvents";
// import LatestDynamic from "./LatestDynamic";
// import Latest from "./LatestDynamic";
// import Email from "../Email";

function Home() {
  return (
    <div id="home">
      <div className="container content">
        <div className="row">
          <div className="col-lg-8 col-xs-12">
            <div className="row">
              {/* <div className="jumbotron shadow-sm">
                <WelcomeMessage />
              </div> */}
              <div className="jumbotron p-5 mb-4 bg-light rounded-3">
                <div className="container-fluid py-5 g-0">
                  <WelcomeMessage />
                </div>
              </div>

              {/* <div className="jumbotron text-center">
                <LatestDynamic />
              </div> */}
              {/* <div className="jumbotron p-5 mb-4 bg-light rounded-3">
                <div className="container-fluid py-5 g-0">
                  <LatestDynamic />
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-xs-12">
            {/* <UpcomingEvents />
            <br /> */}
            <CallCount />
            <div className="row mt-2">
              <div className="col"></div>
              <SocialMedia />
              <div className="col"></div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col">
            <div className="row row-cols-lg-auto text-center g-3 align-items-center">
              <MissionStatement />
              <Motto />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
