import React from 'react';

function FireChiefCommissioners() {
    return (
        <div id="fire-chief" className="content">
            <div className="container">
                <h2 className="page-heading">Fire Chief</h2>

                <h4 className="mt-3">Chief P. L. Graeber</h4>

                <p>Chief Graeber started her emergency services career working as a dispatcher for police and fire facilities in California. She dispatched police, fire, and ambulance and was promoted to supervisor in charge of the 9-1-1 dispatch center.</p>

                <p>After moving to Washington Chief Graeber became the first full-time firefighter/EMT for Mason County Fire District 3 and went on to be hired as the first full-time Fire Chief. Under her direction the district has progressed from an all-volunteer department, to a combination department of paid and volunteer staff and is now a fully paid staffed fire district. </p>
            </div>
        </div>
    );
}

export default FireChiefCommissioners;