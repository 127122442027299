
import React from 'react';

// This only works with images in the public folder
// File paths should not start with a forward slash
// File paths should be relative to public/
function ImageLink(props) {
    const filepath = (props.file && props.file.trim()) || '';
    const altText = (props.alt && props.alt.trim()) || '';
    const classes = props.classes || '';
    return <img src={process.env.PUBLIC_URL + `/` + filepath} className={`${classes} shadow-sm`} alt={altText} />;
}

export default ImageLink;