import React from 'react';
import ImageLink from '../ImageLink';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';

function VolunteerInformation() {
    return (
        <div id="escape-planning" className="content">
            <div className="container">
                <ScrollToTopOnMount />
                <h2 className="mt-4">Volunteer Firefighter / Volunteer Information</h2>
                <div className="row p-1">
                    <div className="col-lg-8 col-sm-12">
                        <article className="mb-4">
                            Volunteer fire companies provide a wide vafiety of services to the community - firefighting, rescue, water rescue, rope rescue, traffic control, medical assistance, assistance with community events and more. Every fire company is different. To find out what we're involved in, call or stop by during business hours.
                    </article>

                        <article>
                            <h5>Volunteers are needed for a wide variety of tasks in local fire departments:</h5>
                            <ul>
                                <li>Provide traffic control at accidents and other emergencies</li>
                                <li>Drivers and pump operators are essential to all emergency operations</li>
                                <li>Support personnel are needed on scene to move hoses, get equipment and many other tasks</li>
                                <li>EMS personnel are needed for prompt medical response</li>
                                <li>Volunteers are needed to teach fire prevention, CPR & First Aid classes, maintenance and cleaning of trucks, etc</li>
                                <li>If you have some time there is very likely a job for you at the Grapeview Fire Department!</li>
                            </ul>
                        </article>

                        <article className="mb-4">
                            All equipment and training is provided at no charge to you. Volunteer firefighters are also covered by the Board for Volunteer Firefighters,, similar to workers' compensation insurance, which covers you should you be injured in the line of duty.
                    </article>

                        <article className="mb-4">
                            The Grapeview community receives fire protection and EMS service from MCFPD#3. We have two fire stations, and our department is dominantly made up of volunteers. These people are dedicated to helping others in their community. And while putting out fires is important, volunteer firefighters do that and dozens of other jobs in their community.
                    </article>

                        <article className="mb-4">
                            <h5>Job Experience / Training</h5>
                            <article>Volunteer firefighters receive free training in firefighting, incident command, hazardous materials, emergency medical training (EMS), and more. Many of these courses can assist you in new career opportunities in either the government or the private sector. The training volunteer firefighters receive can be a springboard to careers as firefighters, emergency service dispatchers, law enforcement officers, security, careers involving hazardous materials, fire prevention and fire safety, etc. Those with EMS experience often go on to careers as paid EMT's or paramedics, nurses, and other health care related careers. If you are thinking about a career in any of these areas this is a chance to "try out" a career and gain valuable experience for your resume.</article>
                        </article>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <figure className="figure">
                            <ImageLink file="images/ems-training-1.jpg" classes="figure-img img-fluid rounded border" alt="2017 Recruit Graduation" />
                        </figure>
                        <figure className="figure">
                            <ImageLink file="images/ems-training-2.jpg" classes="figure-img img-fluid rounded border" alt="EMS training" />
                        </figure>
                        <figure className="figure">
                            <ImageLink file="images/2013-09-20.jpg" classes="figure-img img-fluid rounded border" alt="Image of Grapeview Fire firefighters" />
                        </figure>
                    </div>
                </div>
                <div className="row p-1">
                    <div className="col-lg-8 col-sm-12">
                        <ul>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VolunteerInformation;