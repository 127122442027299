import React from "react";
// import Phone from "./Phone";
// import ImageLink from "./ImageLink";

function OurDistrict() {
  return (
    <div id="our-district" className="content">
      <div className="container">
        <h1 className="page-heading">Our District, Fire District 3</h1>
        <div className="row">
          <div className="col-lg-12 p-5">
            <article>
              As of July 1, 2024, Mason County Fire District 3 merged into
              Central Mason Fire & EMS. For contact information, please visit
              our website at 
              <a
                href="https://cmfe.org/about/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://cmfe.org/about/contact
              </a>
              .
            </article>
          </div>
          {/* <div className="col-lg-8 col-sm-12">
            <article>
              Grapeview Fire Department is a small rural department nestled
              along the western side of Puget Sound, about 8 miles southeast of
              Hood Canal. Encompassing eleven square miles, District 3 is long,
              narrow and heavily wooded with second-growth conifer forests and
              evergreen shrubs. It includes the unincorporated township of
              Grapeview and two small island communities, Stretch Island and
              Treasure Island.
            </article>
            <br />
            <article>
              Grapeview is one of the original homestead areas in Puget Sound
              dating back well into the 1800's. Over 50% of our District has
              saltwater for borders. Bridges are the only access to the majority
              of our district. The district is split between two principal land
              areas with limited access by narrow, two-lane roads and bridges
              over coves and waterways. The topography of Fire Protection
              District 3 extends from hilly wooded areas of up to about 750 feet
              elevation down to sea level, with an extensive shoreline along
              Case Inlet and Pickering Passage. Waterfront homes and cabins are
              interspersed among forests, and are surrounded by our picturesque
              community.
            </article>
            <br />
            <article>
              The Grapeview Fire District was legally established by vote in
              1949 to provide fire suppression services to the Grapeview
              Community. We now provide emergency medical services as well as
              fire protection and fire suppression services. In fact, over 80%
              of the calls are now for medical aid.
            </article>
            <br />
            <article>
              The area served by Fire District 3 and includes most of Grapeview
              Loop Road as well as the roads branching off Grapeview Loop Road,
              Island View, Krabbenhoft and Pickering Road. Services are provided
              from two stations, one at the heart of Grapeview on the corner of
              Grapeview Loop Road and Stretch Island Road, and one on
              Krabbenhoft Road at the intersection with Highway 3. Services are
              provided by 17 highly trained staff and volunteers. Many personnel
              are also trained as Emergency Medical Technicians, Who responded
              to 305 emergency calls last year.
            </article>
            <br />
            <article>
              Volunteers are always needed. New members are needed for EMS, Fire
              and for Support positions. Anyone sixteen and over is welcome, if
              you can help, please call the fire station at{" "}
              360-275-4483.
            </article>
          </div>
          <div id="images" className="col-lg-4 col-sm-12">
            <figure className="figure">
              <ImageLink
                file="images/logo.png"
                classes="figure-img img-fluid rounded"
                alt="Image of the Mason County Fire District 3 logo"
              />
            </figure>
            <figure className="figure">
              <ImageLink
                file="images/newe31-1.jpg"
                classes="figure-img img-fluid rounded border"
                alt="Image of some MCFD3 rigs"
              />
            </figure>
            <figure className="figure">
              <ImageLink
                file="images/Picture_065.jpg"
                classes="figure-img img-fluid rounded border"
                alt="Image of firefighters"
              />
            </figure>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default OurDistrict;
