import React, { useState } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
// import Breadcrumbs from './Breadcrumbs';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';

function Budgets() {
    const [filePath, setFilepath] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showCurrentBudget, setShowCurrentBudget] = useState(false);
    const handleCloseCurrentBudget = () => setShowCurrentBudget(false);
    const handleShowCurrentBudget = () => setShowCurrentBudget(true);
    return (
        <>
            <Container id="budgets" className="content">
                <ScrollToTopOnMount />
                <Container className="container">
                    {/* <Breadcrumbs activePage="Budgets" /> */}
                    <h2 className="page-heading mt-4">Budgets</h2>
                    <section className="mb-3">
                        <Button className="mt-2 mb-2 btn-sm" variant="dark" onClick={handleShowCurrentBudget}>
                            View 2022 Budget
                        </Button><br />
                        <Button className="mt-2 mb-2 btn-sm" variant="dark" onClick={() => {
                            setFilepath(`${process.env.PUBLIC_URL}/files/budget/2021-Budget.pdf`);
                            setModalTitle('2021 Budget');
                            handleShow();
                        }}>
                            View 2021 Budget
                        </Button><br />
                        <Button className="mt-2 mb-2 btn-sm" variant="dark" onClick={() => {
                            setFilepath(`${process.env.PUBLIC_URL}/files/budget/2020-Budget.pdf`);
                            setModalTitle('2020 Budget');
                            handleShow();
                        }}>
                            View 2020 Budget
                        </Button>
                        {/* <embed src={process.env.PUBLIC_URL + `/files/19_1120_Approved-Budget.pdf`} frameBorder="0" width="100%" height="480px" /> */}
                    </section>
                </Container>
            </Container>

            <Modal dialogClassName="" size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={filePath} frameBorder="0" width="100%" height="800px" />
                </Modal.Body>
            </Modal>

            <Modal dialogClassName="" size="lg" show={showCurrentBudget} onHide={handleCloseCurrentBudget}>
                <Modal.Header closeButton>
                    <Modal.Title>2022 Budget</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={process.env.PUBLIC_URL + `/files/budget/2022-Budget.pdf`} frameBorder="0" width="100%" height="800px" />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Budgets;
