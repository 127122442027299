import React from 'react';
import { Link } from 'react-router-dom';
import ImageLink from '../ImageLink';
// import PrintableCovidInfoLinks from './PrintableCovidInfoLinks';
// import SchoolsLinks from './SchoolsLinks';
// import DailyInformation from './DailyInformation';
// import PressRelease from './PressRelease';
// import Letters from './Letters';
import SocialDistancing from './SocialDistancing';
import EarlyTesting from './EarlyTesting';
import Phone from '../Phone';
// import DailyInfoLatest from './DailyInfoLatest';
// import PressReleaseLatest from './PressReleaseLatest';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';

function Covid19() {
    // const listIcon = (
    //     <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    //     <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
    //     </svg>
    // );

    return (
        <div id="covid19" className="content">
            <div className="container">
                <ScrollToTopOnMount />
                <h1 className="page-heading">COVID-19: What You Need To Know</h1>

                <div className="mt-3 mb-3 alert alert-warning" role="alert">
                    For questions about Coronavirus (COVID-19), please call the Washington State Department of Health Coronavirus hotline: dial <Phone number="1-800-525-0127" /> then press #.
                </div>

                <article className="mt-4 mb-4">The Mason County Fire District 3 follows the recommendations of local and state health department agencies, which is where the community is encouraged to go for the latest information and suggestions for staying healthy. Visit the <a href="http://www.co.mason.wa.us/COVID-19/index.php" target="_blank" rel="noopener noreferrer">Mason County COVID-19 page <i className="fa fa-external-link fa-sm" aria-hidden="true"></i>
</a> or the <a href="https://coronavirus.wa.gov/" target="_blank" rel="noopener noreferrer">Washington State COVID-19 page <i className="fa fa-external-link fa-sm" aria-hidden="true"></i>
</a> for more information.</article>

                {/* 2-columns */}
                {/* <div className="row">
                    <div className="col-lg-6 col-xs-12">
                        <DailyInformation />
                    </div>
                    <div className="col-lg-6 col-xs-12">
                        <PressRelease />  
                    </div>
                </div> */}

                {/* <div className="row">
                    <div className="col-lg-6 col-xs-12">
                        <h3>Latest Releases</h3>
                        <ul>
                            <li>Daily information: <DailyInfoLatest /></li>
                            <li>Press release: <PressReleaseLatest /></li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-xs-12">
                        <h3>Archives</h3>
                        <ul className="list-unstyled">
                            <li>{listIcon} <Link to="/covid19/dailyinformation">Daily Information Releases</Link></li>
                            <li>{listIcon} <Link to="/covid19/pressrelease">Press Releases</Link></li>
                        </ul>
                    </div>
                </div> */}

                {/* <Letters /> */}

                <h3 className="mb-4">Washington State Mandatory Face Mask Proclamation is in effect</h3>

                <ImageLink file="files/covid-19/face-mask.jpg" classes="figure-img img-fluid rounded w-75 mx-auto d-block mb-5" alt="Washington State Mandatory Face Mask Proclamation informational poster" />

                <EarlyTesting />

                <ImageLink file="files/covid-19/mason-health-triage-line.png" classes="figure-img img-fluid rounded w-75 mx-auto d-block mb-5" alt="Mason Health Triage Line contact information" />

                <SocialDistancing />

                {/* <h3 className="mt-4">Additional information and resources are listed below:</h3>

                <PrintableCovidInfoLinks />

                <h5 className="mt-3">Local Resources</h5>

                <ul>
                    <li><a href="http://www.co.mason.wa.us/COVID-19/index.php" target="_blank" rel="noopener noreferrer">Mason County COVID-19</a></li>
                    <li><a href="https://www.masongeneral.com/about/covid-19" target="_blank" rel="noopener noreferrer">Mason General Hospital</a></li>
                </ul>

                <SchoolsLinks />

                <h5 className="mt-3">State Resources</h5>

                <ul>
                    <li><a href="https://coronavirus.wa.gov/" target="_blank" rel="noopener noreferrer">WA State Coronavirus (COVID-19) Response</a></li>
                    <li><a href="http://www.doh.wa.gov/" target="_blank" rel="noopener noreferrer">WA State Department of Health (WA DOH)</a></li>
                    <li><a href="https://www.governor.wa.gov/office-governor/official-actions/proclamations" target="_blank" rel="noopener noreferrer">WA State Governor’s Office – Proclamations</a></li>
                </ul>

                <h5 className="mt-3">National Resources</h5>

                <ul>
                    <li><a href="https://www.coronavirus.gov/" target="_blank" rel="noopener noreferrer">Centers for Disease Control and Prevention (CDC)</a></li>
                </ul> */}
                <div className="mt-4 mb-4">
                <h3>Additional Information and Resources</h3>
                <Link to="/covid19/resources">Links to additional COVID-19 resources</Link>
                </div>
            </div>
        </div>
    );
}

export default Covid19;