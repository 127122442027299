import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';
import './custom.scss';
import 'typeface-montserrat';
import { AppContextProvider } from './AppContext';

const container = document.getElementById('root')
const root = createRoot(container);
root.render(
    <AppContextProvider>
        <App />
    </AppContextProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
