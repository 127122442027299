import React, { useEffect, useContext } from "react";
import axios from "axios";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Alerts from "./components/Home/Alerts";
import Home from "./components/Home/Home";
import FireAndSafety from "./components/FireAndSafety";
import HomeFireSafety from "./components/FireAndSafety/HomeFireSafety";
import FireChiefCommissioners from "./components/FireChiefCommissioners";
import ContactUs from "./components/ContactUs";
import Gallery from "./components/Gallery";
import HallRental from "./components/HallRental";
import MeetingMinutes from "./components/MeetingMinutes";
import OtherLinks from "./components/OtherLinks";
import OurDistrict from "./components/OurDistrict";
import VolunteerTraining from "./components/Opportunities";
import Footer from "./components/Footer";
import EscapePlanning from "./components/FireAndSafety/EscapePlanning";
import WaterSafety from "./components/FireAndSafety/WaterSafety";
import KitchenSafety from "./components/FireAndSafety/KitchenSafety";
import SmokeAlarms from "./components/FireAndSafety/SmokeAlarms";
import SmokeDetectorTips from "./components/FireAndSafety/SmokeDetectorTips";
import CprClasses from "./components/FireAndSafety/CprClasses";
import OutdoorBurning from "./components/FireAndSafety/OutdoorBurning";
import BurnBan from "./components/FireAndSafety/BurnBan";
import VolunteerInformation from "./components/Opportunities/VolunteerInformation";
import GoToTop from "./GoToTop";
import Events from "./components/Events";
import HomeCarousel3 from "./components/HomeCarousel3";
import Navigation from "./components/Navigation";
import Header from "./components/Header/Header";
import Logo from "./components/Logo";
import { AppContext } from "./AppContext";
// Covid 19 Links
import Covid from "./components/Covid19/Covid19";
import Resources from "./components/Covid19/Resources";
import DailyInformationInd from "./components/Covid19/DailyInformationInd";
import PressReleaseInd from "./components/Covid19/PressReleaseInd";
// import Posts from './components/Posts';
// import Pages from './components/Pages';
import Commissioners from "./components/Commissioners/Commissioners";
import Budgets from "./components/Commissioners/Budgets";
import ZoomHelp from "./components/Events/ZoomHelp";
// import Agenda from './components/Agenda';
import MeetingMinutesDynamic from "./components/MeetingMinutesDynamic";
import AgendaDynamic from "./components/AgendaDynamic";
import EMSLevyRenewal from "./components/Important/EMSLevyRenewal";

function App() {
  const { setPageData, setPostData, setPagesAreLoadingCallback } =
    useContext(AppContext);

  const postsUrl = "https://grapeviewfire.com/wp/wp-json/wp/v2/posts/";
  const pagesUrl = "https://grapeviewfire.com/wp/wp-json/wp/v2/pages/";
  useEffect(() => {
    async function fetchData() {
      const response = await axios(pagesUrl);
      setPageData(response.data);
      const response2 = await axios(postsUrl);
      setPostData(response2.data);
    }
    async function fetchPageData() {
      const response = await axios(pagesUrl);
      if (response.data) {
        setPageData(response.data);
      }
      setPagesAreLoadingCallback(false);
    }
    fetchData();
    fetchPageData();
  }, [setPageData, setPagesAreLoadingCallback, setPostData]);

  return (
    <Router>
      <div className="container-fluid ps-0 pe-0 App g-0">
        <div id="top">
          <Header />
          <Logo />
          <Navigation />
        </div>
        {/* <Routes>
          <Route
            path="/"
            element={
              <div id="middle">
                <HomeCarousel3 />
              </div>}
          />
          <Route
            path="/home"
            element={
              <div id="middle">      
                <HomeCarousel3 />
              </div>
            }
          />
        </Routes> */}
        {/* <div id="middle"> */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HomeCarousel3 />
                <div id="main" className="container-fluid ps-0 pe-0 g-0">
                  <Alerts />
                  <Home />
                  {/* <Posts /> */}
                  {/* <Pages /> */}
                </div>
              </>
            }
          />
          <Route
            path="/home"
            element={
              <>
                <HomeCarousel3 />
                <Alerts />
                <Home />
              </>
            }
          />
          {/* </Routes> */}
          {/* </div> */}
          {/* <div id="main" className="container-fluid ps-0 pe-0 g-0"> */}
          {/* <Routes> */}
          <Route path="/fireandsafety/*" element={<FireAndSafety />} />
          <Route
            path="/fireandsafety/homefiresafety"
            element={<HomeFireSafety />}
          />
          <Route
            path="/fireandsafety/escapeplanning"
            element={<EscapePlanning />}
          />
          <Route path="/fireandsafety/watersafety" element={<WaterSafety />} />
          <Route
            path="/fireandsafety/kitchensafety"
            element={<KitchenSafety />}
          />
          <Route
            path="/fireandsafety/smokedetectortips"
            element={<SmokeDetectorTips />}
          />
          <Route path="/fireandsafety/smokealarms" element={<SmokeAlarms />} />
          <Route path="/fireandsafety/cprclasses" element={<CprClasses />} />
          <Route
            path="/fireandsafety/outdoorburning"
            element={<OutdoorBurning />}
          />
          <Route path="/fireandsafety/burnban" element={<BurnBan />} />
          <Route path="/firechief" element={<FireChiefCommissioners />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/hallrental" element={<HallRental />} />
          <Route path="/minutes" element={<MeetingMinutes />} />
          <Route path="/meetingminutes" element={<MeetingMinutesDynamic />} />
          <Route path="agenda" element={<AgendaDynamic />} />
          <Route path="/otherlinks" element={<OtherLinks />} />
          <Route path="/ourdistrict" element={<OurDistrict />} />
          <Route path="/opportunities" element={<VolunteerTraining />} />
          <Route
            path="/opportunities/volunteerinformation"
            element={<VolunteerInformation />}
          />
          <Route path="/events" element={<Events />} />
          <Route path="/covid19" element={<Covid />} />
          <Route path="/emslevyrenewal" element={<EMSLevyRenewal />} />
          <Route path="/covid19/resources" element={<Resources />} />
          <Route
            path="/covid19/dailyinformation"
            element={<DailyInformationInd />}
          />
          <Route path="/covid19/pressrelease" element={<PressReleaseInd />} />
          <Route path="/commissioners" element={<Commissioners />} />
          <Route path="/budgets" element={<Budgets />} />
          <Route path="/zoomhelp" element={<ZoomHelp />} />
        </Routes>
        <GoToTop />
        {/* </div> */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
