import React from 'react';
import PrintableCovidInfoLinks from './PrintableCovidInfoLinks';
import SchoolsLinks from './SchoolsLinks';
import Breadcrumbs from '../Covid19/Breadcrumbs';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';

const Resources = () => {
    return (
        <div id="covid19" className="content">
            <div className="container">
                <Breadcrumbs activePage="Resources" />
                <ScrollToTopOnMount />
                <h1 className="page-heading">COVID-19: Additional Information and Resources</h1>

                <PrintableCovidInfoLinks />

                <h5 className="mt-3">Local Resources</h5>

                <ul>
                    <li><a href="http://www.co.mason.wa.us/COVID-19/index.php" target="_blank" rel="noopener noreferrer">Mason County COVID-19</a></li>
                    <li><a href="https://www.masongeneral.com/about/covid-19" target="_blank" rel="noopener noreferrer">Mason General Hospital</a></li>
                </ul>

                <SchoolsLinks />

                <h5 className="mt-3">State Resources</h5>

                <ul>
                    <li><a href="https://coronavirus.wa.gov/" target="_blank" rel="noopener noreferrer">WA State Coronavirus (COVID-19) Response</a></li>
                    <li><a href="http://www.doh.wa.gov/" target="_blank" rel="noopener noreferrer">WA State Department of Health (WA DOH)</a></li>
                    <li><a href="https://www.governor.wa.gov/office-governor/official-actions/proclamations" target="_blank" rel="noopener noreferrer">WA State Governor’s Office – Proclamations</a></li>
                </ul>

                <h5 className="mt-3">National Resources</h5>

                <ul>
                    <li><a href="https://www.coronavirus.gov/" target="_blank" rel="noopener noreferrer">Centers for Disease Control and Prevention (CDC)</a></li>
                </ul>
{/* 
                <div className="mt-4">
                    <Link to="/covid19"><i className="fa fa-caret-left" aria-hidden="true"></i> Go back to COVID-19 Information page</Link>
                </div> */}
            </div>
        </div>
    );
};

export default Resources;
