import React from 'react';
// import FireAndSafetyIntro from './FireAndSafetyIntro';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import Breadcrumbs from './Breadcrumbs';

function HomeFireSafety() {
    return (
        <div id="home-fire-safety" className="content">
            <div className="container">
                <Breadcrumbs activePage="Home Fire Safety" />
                <ScrollToTopOnMount />
                {/* <FireAndSafetyIntro /> */}
                <h2 className="mt-3 mb-4">Home Fire Safety</h2>
                <div className="row p-1">
                    <div className="col-lg-8 col-sm-12">
                        <h6>Downloadable Materials:</h6>
                    The three listed documents below can be printed out to use on this activity.<br />
                        <ul className="keep-as-list mt-3 mb-3">
                            <li><a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + `/images/fire_and_safety_kitchen.gif`} alt="Kitchen diagram">Kitchen diagram</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + `/images/fire_and_safety_livingroom.gif`} alt="Living room diagram">Living room diagram</a></li><li><a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + `/images/fire_and_safety_bedroom.gif`} alt="Bedroom diagram">Bedroom diagram</a></li>
                        </ul>
                        <ol>
                            <li>Identify fire hazards in the home.</li>
                            <li>Learn the importance of correcting home fire safety hazards.</li>
                        </ol>
                        <h6>Materials:</h6>
                    Drawings of three different rooms in a home: a kitchen, living room, and bedroom.<br />
                        <h3 className="mt-4 mb-4">Classroom Activity:</h3>
                        <ol>
                            <li className="mt-2 mb-3">
                                <strong>Ask students if they know what fire hazards are and if they can name things in the home that might be considered a fire hazard.</strong>
                                <div className="pt-3 p-2">
                                    Students will probably identify some of the following:
                                    <br />
                                    <ul className="keep-as-list">
                                        <li>frayed cords on electrical appliances</li>
                                        <li>electrical cords run underneath carpets or furniture</li>
                                        <li>matches and lighters placed where kids can reach them</li>
                                        <li>fireplaces without mesh screens</li>
                                        <li>paper, fabric, trash, or other combustible materials left too close to heat sources such as furnaces, hot water heaters, fireplaces, wood stoves, etc..</li>
                                        <li>material draped over lamps</li>
                                        <li>curtains located too close to the bulbs in torch-style halogen lamps</li>
                                        <li>pot holders or kitchen towels stored too close to stoves</li>
                                        <li>electrical equipment left on with no one is in the room</li>
                                        <li>smoking in bed</li>
                                    </ul>
                                    <br />
                                    Explain that these are dangerous things that could be in anyone's home.
                                </div>
                            </li>
                            <li className="mt-2 mb-3">
                                <strong>Ask students if they know why it is important to identify and correct fire hazards in the home.</strong>
                                <br />
                                Students will probably conclude it is important in order to prevent a fire in the home and to prevent their families from being hurt in a fire. Stress that some hazards may not seem dangerous, such as overloaded extension cords, but that they could cause a fire when they least expect it. Fire hazards are especially dangerous at night, when no one is awake to notice that a fire has started.
                            </li>
                            <li className="mt-3 mb-3">
                                <strong>Review the three rooms in the drawings.</strong>
                                <br />
                                Identify the fire hazards that are found in these rooms. At a minimum, the students should identify: <br />

                                <div className="pt-3 p-2">
                                    <strong>In the kitchen:</strong>
                                    <ul className="keep-as-list">
                                        <li>towel too close to the stove top</li>
                                        <li>child cooking alone</li>
                                        <li>pot handle turned in the wrong direction - it should be turned to the center of the stove to prevent burns</li>
                                        <li>smoke alarm battery missing in hallway</li>
                                    </ul>

                                    <strong>In the living room:</strong>
                                    <ul className="keep-as-list">
                                        <li>overloaded electrical outlet</li>
                                        <li>candle too close to upholstered materials and left unattended
                            T.V. left on and unattended</li>
                                        <li>mesh screen missing from in front of the fireplace</li>
                                        <li>newspapers left too close to the fireplace where a spark could ignite them</li>
                                        <li>smoke alarm battery missing</li>
                                    </ul>

                                    <strong>In the bedroom:</strong>
                                    <ul className="keep-as-list">
                                        <li>clothing draped over lamp where it could start to burn</li>
                                        <li>an object (duck) placed on a space heater where it could start to burn</li>
                                        <li>space heater left on when no one is in the room</li>
                                        <li>clothing left too close to the space heater where it could catch fire</li>
                                        <li>smoke alarm battery missing</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="mt-2 mb-3">
                                <strong>OPTIONAL: Instruct students to draw a home floor plan and inspect their own homes with their parents. </strong>
                                <br />
                                <div className="pt-3 p-2">Have students identify the fire hazards they found in each room. Students should also be able to explain what their parents did to correct the fire hazard. It is important that the students not touch electrical cords or electrical equipment, for example, themselves - a parent or another adult should make the necessary changes.</div>
                            </li>
                        </ol>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <figure className="figure">
                            <img src={process.env.PUBLIC_URL + `/images/fire_and_safety_bedroom.gif`} className="figure-img img-fluid rounded border shadow-sm" alt="Bedroom diagram" />
                            <figcaption className="figure-caption text-end">Bedroom diagram</figcaption>
                        </figure>
                        <figure className="figure">
                            <img src={process.env.PUBLIC_URL + `/images/fire_and_safety_bedroom.gif`} className="figure-img img-fluid rounded border shadow-sm" alt="Kitchen diagram" />
                            <figcaption className="figure-caption text-end">Kitchen diagram</figcaption>
                        </figure>
                        <figure className="figure">
                            <img src={process.env.PUBLIC_URL + `/images/fire_and_safety_livingroom.gif`} className="figure-img img-fluid rounded border shadow-sm" alt="Living room diagram" />
                            <figcaption className="figure-caption text-end">Living room diagram</figcaption>
                        </figure>
                    </div>
                </div>

                <h2 className="mt-4 mb-4">Home Fire Safety Drill for Parents</h2>

                <div className="row p-1">
                    <div className="col">
                        Accompany your child from room to room in your home, looking for possible safety hazards. When the child identifies one, talk about why it could be a hazard, and what can be done to correct it. Emphasize with your child that if they see fire hazards, they should tell you - children should not try to correct them themselves. Electricity and fire can be dangerous things, and children should ask adults to manipulate electrical cords, electrical equipment, etc.
                </div>
                </div>
            </div>
        </div >
    );
}

export default HomeFireSafety;