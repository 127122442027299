import React from 'react';
import positiveInfoList from '../../files/covid/positive.json';
import PressReleaseListItem from './PressReleaseListItem';
import Breadcrumbs from '../Covid19/Breadcrumbs';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';

const PressRelease = () => {
    const goThroughPressReleases = () => {
        const months = ['November', 'October', 'September', 'August', 'July', 'June', 'May', 'April', 'March'];
        return months.map((month, index) => <PressReleaseListItem positiveInfoList={positiveInfoList} month={month} key={`press-release-${month}-2020-${index}`} />);
    };

    return (
        <div id="covid19" className="content">
            <div className="container">
                <ScrollToTopOnMount />
                <Breadcrumbs activePage="Press Releases" />
                <h1 className="page-heading">COVID-19 Press Releases</h1>
                <ul>
                    { goThroughPressReleases() }
                </ul>
            </div>
        </div>
    );
};

export default PressRelease;
