const moment = require('moment');

module.exports = {
    mapCategories(categories) {
        let cat = '';
        categories.forEach(category => cat = `${cat} ${category.name},`);
        return cat.slice(0, cat.length-1);
    },

    mapEvent(event) {
        if (event) {
            return {
                id: event.id,
                title: event.title,
                date: moment(event.date).toDate(),
                start: moment(event.start_date).toDate(),
                end: moment(event.end_date).toDate(),
                allDay: event.all_day,
                image: event.image,
                url: event.url,
                restUrl: event.rest_url,
                desc: event.description,
                cost: event.cost,
                categories: event.categories,
                venue: event.venue,
                website: event.website
            }
        } else {
            return {};
        }
    },

    mapLatestEvents(event) {
        if (event && event.start_date) {
            const today = moment().format("MM/DD/YYYY");
            const eventDate = event && event.start_date && moment(event.start_date).format("MM/DD/YYYY");
            if (eventDate >= today) {
                return {
                    id: event.id,
                    title: event.title,
                    date: moment(event.date).toDate(),
                    start: moment(event.start_date).toDate(),
                    end: moment(event.end_date).toDate(),
                    allDay: event.all_day,
                    image: event.image,
                    url: event.url,
                    restUrl: event.rest_url,
                    desc: event.description,
                    cost: event.cost,
                    categories: event.categories,
                    venue: event.venue,
                    website: event.website
                };
            } else {
                return null;
            }
        } else {
            return null;
        }
    },

    mapVenue(venue) {
        if (venue) {
            return {
                venue: venue.venue,
                address: venue.address,
                city: venue.city,
                state: venue.stateprovince || venue.province,
                zip: venue.zip,
                country: venue.country
            }
        } else {
            return {};
        }
    }
};
