import React, { useState, useCallback } from 'react';

export const AppContext = React.createContext();

export const AppContextProvider = ({ children }) => {
    // const [data, setData] = useState(null);
    const [posts, setPosts] = useState(null);
    const [pages, setPages] = useState(null);
    const [pagesAreLoading, setPagesAreLoading] = useState(true);
    // const [fireChief, setFireChief] = useState(null);
    // const [dailyInfo, setDailyInfo] = useState(null);

    const setPageData = useCallback((_data) => {
        if (_data) {
            setPages(_data);
        }
    }, [setPages]);

    const setPagesAreLoadingCallback = useCallback((value) => {
        setPagesAreLoading(value);
    }, [setPagesAreLoading]);

    const setPostData = useCallback((_data) => {
        // console.log(_data);
        if (_data) {
            setPosts(_data);
            if (_data[0].title && _data[0].title.rendered) {

            }
        }
    }, [setPosts]);
    
    // const setPageData = useCallback((_data) => {
    //     setData(_data);
    //     if (_data) {
    //         _data.forEach((page) => {
    //         // console.log(page.slug);
    //         if (page.slug === 'fire-chief-commissioners') {
    //             setFireChief(page.content.rendered);
    //         } else if (page.slug === 'daily-information') {
    //             const regex = /\s|\\n/gi;
    //             const noSpace = page.content.rendered.replace(regex, '');
    //             const quotes = /(&#8220;)|(&#8221;)/gi;
    //             const rendered = noSpace.replace(quotes, '"');
    //             if (rendered && typeof rendered === 'string') {
    //                 setDailyInfo(JSON.parse(rendered));
    //             }
    //         }
    //         });
    //     }
    // }, [setData]);

    return (
        <AppContext.Provider value={{
            // data,
            setPageData,
            pages,
            pagesAreLoading,
            setPagesAreLoadingCallback,
            // fireChief, // Fire Chief / Commissioners
            // dailyInfo,
            posts,
            setPostData
        }}>
            {children}
        </AppContext.Provider>
    );
};
