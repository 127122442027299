import React from 'react';
import links from '../otherlinks.json';

function OtherLinks () {
    function displayLink(link) {
        return <li key={link.title}><a target="_blank" rel="noopener noreferrer" className="card-link" href={link.url} title={link.title}>{link.title}</a><br /></li>;
    }
    function displayLinks(linkGroup) {
        const heading = linkGroup.heading;
        const links = linkGroup.links;
        return (
            <div id="other-links" className="col-lg-6 col-md-6 col-sm-12" key={heading}>
                <div>
                    <h5>{heading}</h5>
                    <ul>
                    {links.map(displayLink)}
                    </ul>
                </div>
            </div>
        );
    }
    
    return (
        <div id="informational-links" className="content">
            <div className="container">
                <h2 className="page-heading">Informational Links</h2>
                <div className="row">
                    {links.map(displayLinks)}
                </div>
            </div>
        </div>
    );
}

export default OtherLinks;