import React from "react";
import AlertsDynamic from "../Dynamic/AlertsDynamic";

function Alerts() {
  return (
    <div id="alerts" className="shadow-sm content">
      {/* <div id="wrapper" className="container"> */}
      {/* Start COVID-19 Poster modal */}
      <div
        className="modal fade"
        id="pdfModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="pdfModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="pdfModalLabel">
                COVID-19 Poster
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <embed
                src={
                  process.env.PUBLIC_URL + `/files/covid-19/COVID-19-Poster.pdf`
                }
                frameBorder="0"
                width="100%"
                height="500px"
              />
            </div>
            <div className="modal-footer">
              <a
                href={
                  process.env.PUBLIC_URL + `/files/covid-19/COVID-19-Poster.pdf`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-secondary"
              >
                Download
              </a>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column"></div>

      <AlertsDynamic />
      {/* </div> */}
    </div>
  );
}

export default Alerts;
