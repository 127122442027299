import React from "react";

function Email(props) {
  const email = props.email.trim();
  return (
    <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
      {email}
    </a>
  );
}

export default Email;
