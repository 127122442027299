import React from 'react';
import header from '../../images/header2.png';
import headersm from '../../images/header4.png';
import Call911 from './Call911';
import './_Header.scss';

function Header() {
    return (
        <header>
            <div className="ms-0 me-0 ps-0 pe-0">
                <div className="row g-0 align-items-center">
                    <div className="col">
                        <Call911 />
                    </div>
                </div>
            </div>
            <div id="brand" className="text-center text-uppercase">
                <a href="/" className="text-decoration-none text-reset" title="Welcome to Mason County Fire District 3">
                    <span className="fw-bold header" style={{ "fontSize": "3rem" }}>
                        <img className="img-fluid" src={header} alt="Welcome to Mason County Fire District 3" />
                    </span>
                    <span className="fw-bold header-sm" style={{ "fontSize": "3rem" }}>
                        <img className="img-fluid" src={headersm} alt="Welcome to Mason County Fire District 3" />
                    </span>
                </a>
            </div>
        </header>
    );
}

export default Header;
