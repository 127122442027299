import React from 'react';

const EarlyTesting = () => {
    return (
        <>
            <h3 className="mb-4">Eary testing is encouraged</h3>

            <article className="mt-2 mb-4">At the earliest signs of symptoms please reach out to the Respiratory Illness Triage line. They can assist you in scheduling an appointment at Mason Health’s Drive Thru COVID-19 testing.</article>
        </>
    );
};

export default EarlyTesting;
