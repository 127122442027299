import React from 'react';

const HomeCarousel = () => {
    return (
        <div id="middle">
        <div id="photo-carousel" className="content row mx-auto">
            <div className="container-fluid">
            {/* <div className="container"> */}
                <div id="carouselIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselIndicators" data-slide-to="2"></li>
                        {/* <li data-target="#carouselIndicators" data-slide-to="3"></li>
                        <li data-target="#carouselIndicators" data-slide-to="4"></li> */}
                    </ol>
                    <div className="carousel-inner">
                        {/* <div className="carousel-item active">
                            <img src={process.env.PUBLIC_URL + `/images/carousel/as-3.jpg`} className="img-fluid shadow-sm" alt="..." />
                        </div> */}
                        <div className="carousel-item active">
                            <img src={process.env.PUBLIC_URL + `/images/carousel/newe31-1.jpg`} className="img-fluid shadow-sm" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={process.env.PUBLIC_URL + `/images/carousel/newe31-2.jpg`} className="img-fluid shadow-sm" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={process.env.PUBLIC_URL + `/images/carousel/newe31-3.jpg`} className="img-fluid shadow-sm" alt="..." />
                        </div>
                        {/* <div className="carousel-item">
                            <img src={process.env.PUBLIC_URL + `/images/carousel/training-kt.jpg`} className="img-fluid shadow-sm" alt="..." />
                        </div> */}
                    </div>
                    <a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </a>
                </div>
            </div>
        </div>
        </div>
    );
}

export default HomeCarousel;