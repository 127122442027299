import React, { useCallback } from 'react';
import DailyInfoLink from './DailyInfoLink';
import monthConverter from '../../utils/monthConverter';

const DailyInfoListItem = ({ dailyInfo, month, index}) => {
    const displayDailyInfo = useCallback((month, file, fileIndex, totalCount) => {
        return <DailyInfoLink
            month={month}
            altText={'Daily COVID-19 information release for'}
            fileName={file}
            startIndex={'10'}
            index={fileIndex}
            totalCount={totalCount}
            key={`daily-info-list-item-link-${month}-2020-${fileIndex}`}
            />
    }, []);

    return (
        <li key={`daily-info-list-item-${month}-2020-${index}`} className="mb-1">
            <h5 className="mb-0">{month} 2020</h5>
            <ul className="fa-ul">
            { dailyInfo && dailyInfo[month] && dailyInfo[month].map((file, fileIndex) => {
                const fileName = `dailyinfo-${monthConverter(month)}-${file}-2020.pdf`;
                return displayDailyInfo(month, fileName, fileIndex, dailyInfo[month].length); })
            }
            {/* { dailyInfoList[month].map((file, index) => displayDailyInfo(month, file, index, dailyInfoList[month].length)) } */}
            </ul>
        </li>
    );
};

export default DailyInfoListItem;
