import React from 'react';
import fbLogo from '../../images/f_logo_RGB-Blue_58.png';

function SocialMedia() {
    return (
        <div id="facebook-link" className="col-12 text-center">
            <a href="https://www.facebook.com/MCFPD3" target="_blank" rel="noopener noreferrer" className="img-fluid rounded">
                <span>Find our FFA on </span><img src={fbLogo} className="img-fluid" alt="Find our FFA on Facebook" /></a>
        </div>
    );
}

export default SocialMedia;
