import React from 'react';
// import FireAndSafetyIntro from './FireAndSafetyIntro';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import Breadcrumbs from './Breadcrumbs';

function WaterSafety() {
    return (
        <div id="water-safety" className="content">
            <div className="container">
                <Breadcrumbs activePage="Water Safety" />
                <ScrollToTopOnMount />
                {/* <FireAndSafetyIntro /> */}
                <h2 className="mt-3 mb-4">Water Safety</h2>
                <div className="row g-0">
                    <div className="col-lg-9 col-sm-12">
                        <h3>Drowning Prevention and Water Safety Tips</h3>
                        <article className="mt-3">
                            <h4>Pool Safety</h4>
                            <ul className="keep-as-list mt-3">
                                <li>The adult who is watching should do just that—watch.</li>
                                <li>Have a phone in the pool area to place emergency calls; do not leave for even a second to answer the phone.</li>
                                <li>Do not talk on the phone or even read a book or magazine while watching children around the pool.</li>
                                <li>Do not depend on toy flotation devices (like floaties that go on a child’s upper arm) for lifesaving; because they contain air, they are just like other pool toys.</li>
                                <li>Make sure the gate or door that leads to the pool is always locked. Interior fences provide an additional barrier and should have self-closing, self-latching gates.</li>
                                <li>Remember the A-B-C's for water safety: Always have adult supervision around water.</li>
                                <li>Barrier fencing around your pool is extremely important.</li>
                                <li>CPR: get involved—take a CPR class.</li>
                                <li>Supervision is needed around ALL bodies of water: Never allow your children to play around canals; they are deep and have strong undercurrents.</li>
                                <li>Never leave your children while bathing them in the tub or sink. Always have everything you need on hand before you start their bath.</li>
                                <li>Children can also drown in buckets, the toilet, or even the dog’s water dish.</li>
                                <li>The best drowning prevention tip is to always know where your children are, especially when around water.</li>
                            </ul>
                            <h3>Boaters! Before Getting Underway:</h3>
                            <strong>Know your boat and know the rules of the road. Take a safe boating course.</strong>
                            <ul className="keep-as-list mt-3">
                                <li>Check your boat for all required safety equipment.</li>
                                <li>Consider the size of your boat, the number of passengers and the amount of extra equipment that will be on-board. DON’T OVERLOAD THE BOAT!</li>
                                <li>If you will be in a power boat, check your electrical system and fuel system for gas fumes.</li>
                                <li>Follow manufacturer’s suggested procedures BEFORE starting up the engine.</li>
                                <li>Wear your life jacket – don’t just carry one on board.</li>
                                <li>Leave your alcohol behind. Work to increase your safety, not increase your risks!</li>
                                <li>Check the weather forecast.</li>
                                <li>File a float plan with a member of your family or friend.</li>
                            </ul>
                            <h3>Cold Water Survival!</h3>
                            <article className="mt-3">Your life may depend on a better understanding of cold water. Many suspected drowning victims actually die from cold exposure or hypothermia.</article>

                            <article className="mt-3">Hypothermia is a condition in which the body loses heat faster than it can produce it. Violent shivering develops which may give way to confusion and a loss of body movement.</article>

                            <article className="mt-3">If you fall in the water, in any season, hypothermia may occur. Many of our nation’s open waters are mountain fed, and water temperatures even in late summer can run low enough to bring on this condition under certain conditions. It’s important to remember:</article>
                            <ul className="keep-as-list mt-3">
                                <li>Don’t discard clothing. Clothing layers provide some warmth that may actually assist you in fighting hypothermia. This includes shoes and hats.</li>
                                <li>Wear your life jacket! This helps hold heat into the core areas of your body, and enables you to easily put yourself into the HELP position. HELP (Heat Escape Lessening Posture) by drawing limbs into your body; keep armpits and groin areas protected from unnecessary exposure – a lot of heat can be lost from those areas, as well as the head.</li>
                            </ul>

                            <h3>Beach & Water Safety</h3>
                            <article>
                                Tragic water accidents happen quickly. The most common reason for aquatic mishaps is a lack of safety knowledge. Lifeguards recommend the following safety tips:
                        </article>
                            <ul className="keep-as-list mt-3">
                                <li>Learn to swim</li>
                                <li>Swim near a lifeguard</li>
                                <li>Never swim alone</li>
                                <li>Supervise children closely, even when lifeguards are present</li>
                                <li>Don't rely on floation devices, such as rafts, you may lose them in the water</li>
                                <li>If caught in a rip current, swim sideways until free, don't swim against the current's pull</li>
                                <li>Alcohol and swimming don't mix</li>
                                <li>Protect your head, neck, and spine -- don't dive into unfamiliar waters -- feet first, first time</li>
                                <li>If you are in trouble, call or wave for help</li>
                                <li>Follow regulations and lifeguard directions</li>
                                <li>Swim parallel to shore if you wish to swim long distances</li>
                                <li>Scuba dive only if trained and certified -- and within the limits of your training</li>
                                <li>No glass containers at the beach -- broken glass and bare feet don't mix</li>
                                <li>No beach fires except in designated areas -- fire residue and superheated sand can severely burn bare feet -- use a barbeque that is elevated off the sand</li>
                                <li>Report hazardous conditions to lifeguards or other beach management personnel</li>
                                <li>Stay clear of coastal bluffs, they can collapse and cause injury</li>
                                <li>Never turn your back to the ocean -- you may be swept off coastal bluffs or tide pool areas and into the water by waves that can come without warning</li>
                            </ul>

                            <h4 className="mt-4">Rip Currents</h4>
                            <article className="mt-3">Rip currents are the most threatening natural hazard along our coast. They pull victims away from the beach. The United States Lifesaving Association has found that 80% of the rescues effected by ocean lifeguards involve saving those caught in rip currents.</article>

                            <article className="mt-3">A rip current is a seaward moving current that circulates water back to sea after it is pushed ashore by waves. Each wave accumulates water on shore creating seaward pressure. This pressure is released in an area with the least amount of resistance which is usually the deepest point along the ocean floor. Rip currents also exist in areas where the strength of the waves are weakened by objects such as rock jetties, piers, natural reefs, and even large groups of bathers. Rip currents often look like muddy rivers flowing away from shore.</article>

                            <article className="mt-3">Rip currents are sometimes mistakenly called "rip tides" or "undertows." These are misnomers. Rip currents are not directly associated with tides and they do not pull people under.</article>

                            <article className="mt-3"> Try to avoid swimming where rip currents are present, but if you become caught in a one, swim parallel to the shore until the pull stops and then swim back to shore. If you are unable to return to the beach, tread water and wave for lifeguard assistance. </article>

                            <article className="mt-3">Be aware of ocean conditions. Lifeguards are trained to identify potential hazards. Ask a lifeguard about the conditions before entering the water.</article>
                        </article>
                    </div>
                    <div id="images" className="col-lg-3 col-sm-12">
                        <figure className="figure">
                            <img src={process.env.PUBLIC_URL + `/images/water_safety_1.jpg`} className="figure-img img-fluid rounded border shadow-sm" alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default WaterSafety;