import React from 'react';
import ImageGallery from 'react-image-gallery';

const images = [
  // {
  //   original: process.env.PUBLIC_URL + `/images/gallery/training-kt.jpg`,
  //   originalClass: 'img-fluid',
  //   thumbnail: process.env.PUBLIC_URL + `/images/gallery/training-kt.jpg`,
  //   //   thumbnailClass: 'img-thumbnail'
  // },
  {
    original: process.env.PUBLIC_URL + `/images/gallery/as-1.jpg`,
    originalClass: 'img-fluid',
    thumbnail: process.env.PUBLIC_URL + `/images/gallery/as-1.jpg`,
    //   thumbnailClass: 'img-thumbnail'
  },
  {
    original: process.env.PUBLIC_URL + `/images/gallery/as-2.jpg`,
    originalClass: 'img-fluid',
    thumbnail: process.env.PUBLIC_URL + `/images/gallery/as-2.jpg`,
    //   thumbnailClass: 'img-thumbnail'
  },
  // {
  //   original: process.env.PUBLIC_URL + `/images/gallery/as-3.jpg`,
  //   originalClass: 'img-fluid',
  //   thumbnail: process.env.PUBLIC_URL + `/images/gallery/as-3.jpg`,
  //   //   thumbnailClass: 'img-thumbnail'
  // },
  {
    original: process.env.PUBLIC_URL + `/images/gallery/as-4.jpg`,
    originalClass: 'img-fluid',
    thumbnail: process.env.PUBLIC_URL + `/images/gallery/as-4.jpg`,
    //   thumbnailClass: 'img-thumbnail'
  },
  {
    original: process.env.PUBLIC_URL + `/images/gallery/as-5.jpg`,
    originalClass: 'img-fluid',
    thumbnail: process.env.PUBLIC_URL + `/images/gallery/as-5.jpg`,
    //   thumbnailClass: 'img-thumbnail'
  },
  {
    original: process.env.PUBLIC_URL + `/images/gallery/as-6.jpg`,
    originalClass: 'img-fluid',
    thumbnail: process.env.PUBLIC_URL + `/images/gallery/as-6.jpg`,
    //   thumbnailClass: 'img-thumbnail'
  },
  {
    original: process.env.PUBLIC_URL + `/images/gallery/as-7.jpg`,
    originalClass: 'img-fluid',
    thumbnail: process.env.PUBLIC_URL + `/images/gallery/as-7.jpg`,
    //   thumbnailClass: 'img-thumbnail'
  },
  {
    original: process.env.PUBLIC_URL + `/images/gallery/as-8.jpg`,
    originalClass: 'img-fluid',
    thumbnail: process.env.PUBLIC_URL + `/images/gallery/as-8.jpg`,
    //   thumbnailClass: 'img-thumbnail'
  }
];

function Gallery() {
  return (
    <div id="gallery" className="content">
      <div className="container">
        <h1 className="page-heading">Gallery</h1>
        <ImageGallery items={images} />
      </div>
    </div>
  );
}

export default Gallery;