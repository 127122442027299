import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventAgenda from "./EventAgenda";
import Event from "./Event";
import eventUtil from "../../utils/event";

const localizer = momentLocalizer(moment);

function MyCalendar() {
  const eventsUrl = 'https://grapeviewfire.com/wp/wp-json/tribe/events/v1/events';
  const [calendar, setCalendar] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, []);

  useEffect(() => {
    setLoading(true);
    let constants;
    fetch("files/constants.json")
      .then((response) => response.json())
      .then((response) => {
        constants = response?.[0];
        let START_DATE =
          constants?.MY_CALENDAR?.START_DATE ||
          moment(new Date()).format("YYYY-MM-DD"); // Today's date
        fetch(`${eventsUrl}?start_date=${START_DATE}`)
          .then(async (res) => res.json())
          .then(async (cal) => {
            const totalPages = cal.total_pages;
            let PER_PAGE =
              cal?.total < constants.MY_CALENDAR.PER_PAGE
                ? cal?.total
                : constants.MY_CALENDAR.PER_PAGE;

            let promises = [];
            if (totalPages) {
              for (let pageIndex = 1; pageIndex <= totalPages; pageIndex++) {
                let pageUrl = `${eventsUrl}?page=${pageIndex}&per_page=${PER_PAGE}&start_date=${START_DATE}`;
                promises.push(fetch(pageUrl));
              }
              let allEvents = [];
              Promise.allSettled(promises).then((results) => {
                results.forEach(async (result) => {
                    if (result.status === 'fulfilled' && result.value?.status === 200) {
                        const res = await result.value.json();
                        const wth = res.events.map(eventUtil.mapEvent);
                        allEvents = allEvents.concat(wth);
                        setCalendar(allEvents);
                    }
                });
              });
              setLoading(false);
            }
            // if (cal.total > 0) {
            //   fetch(`${url}?start_date=${START_DATE}&per_page=${PER_PAGE}`)
            //     .then((res) => res.json())
            //     .then((cal) => {
            //       //   setEvents(cal.events.map(eventUtil.mapLatestEvents));
            //       setCalendar(cal.events.map(eventUtil.mapEvent));
            //       setLoading(false);
            //     });
            // } else {
            //   setLoading(false);
            // }
          });
      });
    return () => {};
  }, []);

  const renderCalendar = () => {
    const formats = {
      eventTimeRangeFormat: () => "",
    };
    return (
      <div>
        <Calendar
          popup
          events={calendar}
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          // style={{ height: "800px" }}
          startAccessor="start"
          endAccessor="end"
          titleAccessor="test"
          formats={formats}
          components={{
            event: Event,
            agenda: {
              event: EventAgenda,
            },
            week: {
              event: Event,
            },
          }}
          scrollToTime={new Date(new Date().setHours(7, 0))}
        />
      </div>
    );
  };

  const renderSpinner = () => {
    return (
      <div className="d-flex justify-content-center mb-3">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };

  return loading ? renderSpinner() : renderCalendar();
}

export default MyCalendar;
