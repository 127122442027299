const convertMonth = (monthToConvert) => ({
    December: '12',
    November: '11',
    October: '10',
    September: '09',
    August: '08',
    July: '07',
    June: '06',
    May: '05',
    April : '04',
    March: '03'
})[monthToConvert];

export default convertMonth;
