import React from 'react';
// import FireAndSafetyIntro from './FireAndSafetyIntro';
import Phone from '../Phone';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import Breadcrumbs from './Breadcrumbs';

function SmokeAlarms() {
    return (
        <div id="smoke-alarms" className="content">
            <div className="container">
                <Breadcrumbs activePage="Smoke Alarms" />
                <ScrollToTopOnMount />
                {/* <FireAndSafetyIntro /> */}
                <h2 className="mt-4">Smoke Alarms: What You Need To Know</h2>
                <div className="row">
                    <div className="col">

                        <article className="mb-3">In the event of a fire, properly installed and maintained smoke alarms will provide an early warning alarm to your household. This alarm could save your own life and those of your loved ones by providing the chance to escape.</article>

                        <h5 className="mt-3">Why Should My Home Have Smoke Alarms?</h5>

                        <article className="mb-3">
                            In the event of a fire, a smoke alarm can save your life and those of your loved ones. They are a very important means of preventing house and apartment fire fatalities by providing an early warning signal -- so you and your family can escape. Smoke alarms are one of the best safety devices you can buy and install to protect yourself, your family, and your home.
                    </article>

                        <h5>What Types of Smoke Alarms Are Available?</h5>

                        <article className="mb-2">
                            There are many different brands of smoke alarms available on the market but they fall under two basic types:  ionization and photoelectric.
                    </article>

                        <article className="mb-2">
                            <b>Ionization alarms</b> sound more quickly when a flaming, fast moving fire occurs. <b>Photoelectric alarms</b> are quicker at sensing smoldering, smoky fires. There are also combination smoke alarms that combine ionization and photoelectric into one unit, called <b>dual sensor smoke alarms</b>.
                    </article>

                        <article className="mb-2">
                            Because both ionization and photoelectric smoke alarms are better at detecting distinctly different yet potentially fatal fires, and because homeowners cannot predict what type of fire might start in a home, the USFA recommends the installation of both ionization and photoelectric or dual sensor smoke alarms.
                    </article>


                        <article className="mb-3">In addition to the basic types of alarms, there are alarms made to meet the needs of people with hearing disabilities. These alarms may use strobe lights that flash and/or vibrate to assist in alerting those who are unable to hear standard smoke alarms when they sound.</article>


                        <h5>Okay, Where Do I Put Them?</h5>

                        <article className="mb-3">Install smoke alarms on every level of your home, including the basement. Many fatal fires begin late at night or in the early morning. For extra safety, install smoke alarms both inside and outside sleeping areas. Since smoke and many deadly gases rise, installing your smoke alarms at the proper level will provide you with the earliest warning possible. Always follow the manufacturer’s installation instructions.</article>

                        <h5>Where Would I Get Smoke Alarms?</h5>

                        <article className="mb-3">Many hardware, home supply, or general merchandise stores carry smoke alarms. If you are unsure where to buy one in your community, call your local fire department (on a non-emergency telephone number) and they will provide you with some suggestions. Some fire departments offer smoke alarms for little or no cost.</article>

                        <h5>Are Smoke Alarms Hard to Install?</h5>

                        <article className="mb-2">
                            If your smoke alarms are hard-wired, that is wired into the electrical system, you will need to have a qualified electrician do the initial installation or install replacements.  For battery powered smoke alarms, all you will need for installation is a screw driver. Some brands are self-adhesive and will easily stick to the wall or ceiling where they are placed.  For all smoke alarm installations, be sure you follow the manufacturer’s instructions because there are differences between the various brands.  If you are uncomfortable standing on a ladder, ask a relative or friend for help. Some fire departments will install a smoke alarm in your home for you. Call your local fire department (on a non-emergency telephone number) if you have problems installing a smoke alarm.
                </article>

                        {/* <article className="m-3 p-3 border rounded-3"> */}
                        <article className="card mb-4">
                            <div className="card-header">Helpful Tip</div>
                            <div className="card-body">
                                Pick a holiday or your birthday and replace the batteries each year on that day.
                                <br />
                                If your smoke alarm starts making a "chirping" noise, replace the batteries and reset it.
                            </div>
                        </article>

                        <h5>How Do I Keep My Smoke Alarm Working?</h5>

                        <article className="mb-3">
                            If you have a smoke alarm with batteries:

                            <ol className="keep-as-list mt-3">
                                <li>Smoke Alarms powered by long-lasting batteries are designed to replace the entire unit according to manufacturer’s instructions.</li>
                                <li>In standard type battery powered smoke alarms, the batteries need to be replaced at least once per year and the whole unit should be replaced every 8-10 years.</li>
                                <li>In hard-wired, battery back up smoke alarms, the batteries need to be checked monthly, and replaced at least once per year. The entire unit should be replaced every 8-10 years.</li>
                            </ol>

                        </article>

                        <h5>What if the Alarm Goes Off While I’m Cooking?</h5>

                        <article className="mb-3">
                            Then it’s doing its job. Do not disable your smoke alarm if it alarms due to cooking or other non-fire causes. You may not remember to put the batteries back in the alarm after cooking.  Instead clear the air by waving a towel near the alarm, leaving the batteries in place. The alarm may need to be moved to a new location. Some of the newer models have a “hush” button that silences nuisance alarms.
                    </article>

                        <h5>How Long will my Smoke Alarm Last?</h5>

                        <article className="mb-3">
                            Most alarms installed today have a life span of about 8-10 years. After this time, the entire unit should be replaced. It is a good idea to write the date of purchase with a marker on the inside of your alarm so you will know when to replace it. Some of the newer alarms already have the purchase date written inside. In any event, always follow the manufacturer’s instructions for replacement.
                    </article>

                        <h5>Anything Else I Should Know?</h5>

                        <article className="mb-3">
                            Some smoke alarms are considered to be “hard-wired.” This means they are connected to the household electrical system and may or may not have battery backup. It’s important to test every smoke alarm monthly and replace the batteries with new ones at least once a year.
                    </article>

                        <h5>The U.S. Fire Administration would like to remind you of some important fire safety and prevention information.</h5>

                        <ul className="keep-as-list mt-3">
                            <li>Plan and practice escape plans several times a year.</li>
                            <li>Make sure your whole family knows when and how to call emergency telephone numbers.</li>
                            <li>Obtain and learn how to use a fire extinguisher.</li>
                            <li>Install carbon monoxide detectors.</li>
                            <li>Consider installing residential fire sprinklers in your home.</li>
                        </ul>

                        <article className="mb-3">Contact the <strong>Grapeview Fire Department</strong> at <Phone number="360-275-4483" /> if you need help or have questions about fire safety in your home.</article>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SmokeAlarms;