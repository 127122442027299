import React from 'react';
import { Link } from "react-router-dom";
import logo from '../images/logo.png';

function Logo() {
    return (
        <div id="logo">
            <Link to="/home" className="navbar-brand d-none d-lg-block" href="#">
            <img src={logo} alt="Welcome to Mason County Fire District 3" width="125" height="124" /><span className="visually-hidden">(current)</span>
            </Link>
        </div>
    );
}

export default Logo;
