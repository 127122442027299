import React, { useState, useEffect } from "react";
import moment from "moment";
import eventUtil from "../../utils/event";
import dompurify from "dompurify";
import { Link } from "react-router-dom";

const sanitizer = dompurify.sanitize;

const AllEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  //   const offset = today.getTimezoneOffset();
  //   yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  //   return yourDate.toISOString().split("T")[0];
  // const [constants, setConstants] = useState({});

  useEffect(() => {
    setLoading(true);
    // fetch('data/users.json').then((res)=>res.json()).then((data)=>{
    //     // setUsers(data)
    // });
    let constants;
    fetch("files/constants.json")
      .then((response) => response.json())
      .then((response) => {
        constants = response?.[0];
        const url =
          'https://grapeviewfire.com/wp/wp-json/tribe/events/v1/events';
        fetch(url)
          .then((res) => res.json())
          .then((cal) => {
            let START_DATE =
              constants?.ALL_EVENTS?.START_DATE ||
              moment(new Date()).format("YYYY-MM-DD"); // Today's date
            let PER_PAGE = cal?.total || constants.ALL_EVENTS.PER_PAGE;
            if (cal.total > 0) {
              fetch(
                `${url}?start_date=${START_DATE}&per_page=${PER_PAGE}`
              )
                .then((res) => res.json())
                .then((cal) => {
                  if (cal.events) {
                    setEvents(cal.events.map(eventUtil.mapEvent));
                  }
                  setLoading(false);
                });
            } else {
              setLoading(false);
            }
          });
      });
    return () => {};
  }, []);

  const displayMulti = (start, end, event) => {
    return (
      <td>
        {event.start && moment(event.start).format("dddd")} -{" "}
        {event.end && moment(event.end).format("dddd")}
        <br />
        {start} - {end}
      </td>
    );
  };

  const displayOne = (event) => {
    const startTime =
      event && event.start && moment(event.start).format("h:mm a");
    let endTime =
      event && event.end ? ` - ${moment(event.end).format("h:mm a")}` : null;
    if (event && event.start && event.end) {
      if (
        moment(event.start).format("h:mm a") ===
        moment(event.end).format("h:mm a")
      ) {
        endTime = null;
      }
    }
    const day = event.start && moment(event.start).format("dddd");
    const fullDate = event.start && moment(event.start).format("MM/DD/YYYY");
    return (
      <td>
        {day}
        <br />
        {fullDate}
        <br />
        {startTime}
        {endTime}
      </td>
    );
  };

  const displayMultiNotTable = (start, end, event) => {
    const startDay = event.start && moment(event.start).format("dddd");
    const endDay = event.end && moment(event.end).format("dddd");
    return (
      <span>
        <strong>
          {startDay} - {endDay}
          <br />
          {start} - {end}
        </strong>
      </span>
    );
  };

  const displayOneNotTable = (event) => {
    const startDay = event.start && moment(event.start).format("dddd");
    const startDate = event.start && moment(event.start).format("MM/DD/YYYY");
    const startTime =
      event && event.start && moment(event.start).format("h:mm a");
    let endTime =
      event && event.end ? ` - ${moment(event.end).format("h:mm a")}` : null;
    if (event && event.start && event.end) {
      if (
        moment(event.start).format("h:mm a") ===
        moment(event.end).format("h:mm a")
      ) {
        endTime = null;
      }
    }
    return (
      <span>
        <strong>{startDay}</strong>, <strong>{startDate}</strong> at{" "}
        <strong>
          {startTime}
          {endTime}
        </strong>
      </span>
    );
  };

  const displayEvent = (event) => {
    const venue = eventUtil.mapVenue(event.venue);
    const start = event && event.start;
    const end = event && event.end;
    const isMultiDay =
      moment(start).format("MM/DD/YYYY") !== moment(end).format("MM/DD/YYYY");
    const formattedStart = moment(start).format("MM/DD/YYYY");
    const formattedEnd = moment(end).format("MM/DD/YYYY");
    let isZoomMeeting = false;
    if (
      event.website &&
      event.website.length > 0 &&
      event.website.toLowerCase().indexOf("zoom") > -1
    ) {
      isZoomMeeting = true;
    }
    return (
      <tr key={event.id}>
        <th scope="row">
          <strong>{event.title}</strong>
        </th>
        {isMultiDay
          ? displayMulti(formattedStart, formattedEnd, event)
          : displayOne(event)}
        {venue && venue.venue ? (
          <>
            <td>
              <span>
                {venue.venue && venue.venue.toString()}
                <br />
                {venue.address}
                <br />
                {venue.city ? `${venue.city}, ` : null}
                {venue.state} {venue.zip}
              </span>
              <br />
              {event.website ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={event.website}
                  alt={`${event.title} website`}
                >
                  Visit the event website
                </a>
              ) : null}
            </td>
          </>
        ) : (
          <td>
            {event.website ? (
              <>
                <i
                  className="fa fa-video-camera small ps-1 pe-1"
                  aria-hidden="true"
                />{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={event.website}
                  alt={`${event.title} website`}
                  className="fw-bold"
                >
                  {isZoomMeeting
                    ? "Join the online Zoom meeting"
                    : "Visit the event website"}
                </a>
                <br />
                <span
                  dangerouslySetInnerHTML={{ __html: sanitizer(event.desc) }}
                />
                {isZoomMeeting ? (
                  <div className="mt-2">
                    <i
                      className="fa fa-question-circle small pe-1"
                      aria-hidden="true"
                    />{" "}
                    <Link to="/zoomhelp" className="text-center small mt-2">
                      Need help joining Zoom meetings?
                    </Link>
                  </div>
                ) : null}
              </>
            ) : null}
          </td>
        )}
      </tr>
    );
  };

  const displayEventToo = (event) => {
    const venue = eventUtil.mapVenue(event.venue);
    const start = event && event.start;
    const end = event && event.end;
    const isMultiDay =
      moment(start).format("MM/DD/YYYY") !== moment(end).format("MM/DD/YYYY");
    const formattedStart = moment(start).format("MM/DD/YYYY");
    const formattedEnd = moment(end).format("MM/DD/YYYY");
    let isZoomMeeting = false;
    if (
      event.website &&
      event.website.length > 0 &&
      event.website.toLowerCase().indexOf("zoom") > -1
    ) {
      isZoomMeeting = true;
    }
    return (
      <tr key={event.id}>
        <td>
          <h4>{event.title}</h4>
          {isMultiDay
            ? displayMultiNotTable(formattedStart, formattedEnd, event)
            : displayOneNotTable(event)}
          {venue && venue.venue ? (
            <>
              <br />
              <span>
                {venue.venue.toString()}
                <br />
                {venue.address}
                <br />
                {venue.city ? `${venue.city}, ` : null}
                {venue.state} {venue.zip}
              </span>
            </>
          ) : null}
          {event.website ? (
            <>
              <br />
              <i
                className="fa fa-video-camera small ps-1 pe-1"
                aria-hidden="true"
              />{" "}
              <span className="fw-bold">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={event.website}
                  alt={`${event.title} website`}
                >
                  {isZoomMeeting
                    ? "Join the online Zoom meeting"
                    : "Visit the event website"}
                </a>
              </span>
              <br />
              <span
                dangerouslySetInnerHTML={{ __html: sanitizer(event.desc) }}
              />
              {isZoomMeeting ? (
                <div className="mt-2">
                  <i
                    className="fa fa-question-circle small pe-1"
                    aria-hidden="true"
                  />{" "}
                  <Link to="/zoomhelp" className="text-center small mt-2">
                    Need help joining Zoom meetings?
                  </Link>
                </div>
              ) : null}
            </>
          ) : null}
        </td>
      </tr>
    );
  };

  const renderAllEvents = () => {
    if (loading)
      return (
        <div className="d-flex justify-content-center mb-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    return (
      <>
        <div
          id="all-events"
          className="d-sm-none d-md-block d-none table-responsive shadow-sm"
        >
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">What</th>
                <th scope="col">When</th>
                <th scope="col">Where</th>
              </tr>
            </thead>
            <tbody>{events.map(displayEvent)}</tbody>
          </table>
        </div>
        <div className="d-block d-sm-none d-none d-sm-block d-md-none table-responsive">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Events</th>
              </tr>
            </thead>
            <tbody>{events.map(displayEventToo)}</tbody>
          </table>
        </div>
      </>
    );
  };

  const renderNoEvents = () => {
    if (loading)
      return (
        <div className="d-flex justify-content-center mb-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    return (
      <div className="card-body text-center pb-0 pe-2 ps-2" key="no-events">
        <p className="card-text">
          <span className="fw-bolder">
            No upcoming events to display at this time
          </span>
        </p>
      </div>
    );
  };

  return events.length > 0 ? renderAllEvents() : renderNoEvents();
};

export default AllEvents;
