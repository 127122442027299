import React from 'react';
// import FireAndSafetyIntro from './FireAndSafetyIntro';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import Breadcrumbs from './Breadcrumbs';

function EscapePlanning() {
    return (
        <div id="escape-planning" className="content">
            <div className="container">
                <Breadcrumbs activePage="Escape Planning" />
                <ScrollToTopOnMount />
                {/* <FireAndSafetyIntro /> */}
                <h2 className="mt-3 mb-4">Escape Planning</h2>
                <div className="row p-1">
                    <div className="col-lg-9 col-sm-12">
                        <h6>Downloadable Materials:</h6>
                    The two listed documents below can be printed out to use on this activity.<br />
                        <ul className="mt-2 mb-4">
                            <li><a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + `/documents/escape_plan.pdf`} alt="Link to download the escape plan diagram PDF file">Escape plan diagram</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + `/documents/maze.pdf`} alt="Link to download the escape maze PDF file">Escape Maze</a></li>
                        </ul>
                        <ol>
                            <li className="mt-2 mb-2">
                                <strong>Ask children if they have ever wondered what they would do if there was a fire in their home. </strong>
                                <div className="m-2">
                                    <strong>Stress that it's important to get out fast.</strong><br />
                                    <ul className="keep-as-list">
                                        <li>They should never hide or take time to gather up their toys or other belongings.</li>
                                        <li>Fires are scary and confusing.</li>
                                        <li>They can be loud, burn very fast and their smoke can make a room or home very dark.</li>
                                        <li>It helps to have a plan to know what to do if there is a fire and to get out fast. </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="mt-2 mb-2">
                                <strong>Ask students if they know what a home fire escape plan is.</strong>
                                <ul className="keep-as-list">
                                    <li>Be sure to stress the importance of planning for two ways out in case one means of egress is blocked by fire.</li>
                                </ul>
                            </li>
                            <li className="mt-2 mb-2">
                                <strong>Ask students why they think a home fire escape plan is important.</strong><br />
                                <ul className="keep-as-list">
                                    <li>Students will probably conclude that home fire escape plans help them to get out quickly and safely in the event of fire.</li>
                                </ul>
                            </li>
                            <li className="mt-2 mb-2">
                                <strong>Ask students if they have a home fire escape plan and if they practice it regularly with an adult.</strong>
                            </li>
                            <li className="mt-2 mb-2">
                                <a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + `/documents/maze.pdf`} alt="Link to download the escape maze PDF file">Download the escape maze.</a> Ask students to find two ways out of the escape maze.
                            <ul>
                                    <li>Remind them that just like the maze, good home fire escape plans have two ways out.</li>
                                </ul>
                            </li>
                            <li className="mt-2 mb-2">
                                <strong><a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + `/documents/escape_plan.pdf`} alt="Link to download the escape plan diagram PDF file">Download the escape plan diagram PDF file.</a></strong><br />
                                <ul className="keep-as-list">
                                    <li>Using the small space, ask students to draw a picture of their bedroom.</li>
                                    <li>Have them mark where the doors and windows are, and then make an escape plan by drawing arrows to show two ways out. Remind students that the first way out should be a door.</li>
                                    <li>Using the large space, ask students to draw their home, showing the location of windows and doors they can use to escape. Remind students that every way needs to be planned and practiced with an adult.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Review the fire escape plans with the class, emphasizing the following fire safety tips:</strong><br />
                                <ul className="keep-as-list">
                                    <li>
                                        Before opening any door in a fire, feel it first. If it is hot, there may be fire on the other side. Try to get out another way.
                                </li>
                                    <li>
                                        Crawl low on the floor to avoid heat and smoke.
                                </li>
                                    <li>
                                        Pick a safe and easy-to-remember place outside the home to meet the rest of the family.
                                </li>
                                    <li>
                                        Call 9-1-1 after escaping from the fire.
                                </li>
                                    <li>
                                        Stay outside no matter what; don't go back for anything.
                                </li>
                                    <li>
                                        Conclude the lesson by asking students to practice their home fire escape plans with an adult when they go home.
                                </li>
                                </ul>
                            </li>
                        </ol>
                        <h3 className="mt-4 mb-3">Home Fire Escape Drill for Parents</h3>
                        <div className="row g-0">
                            <article className="mb-3">Using the escape plan diagrams in #6 above, ask your child to lead you out of their bedroom and then your home, practicing both ways out of each. To simulate escaping from a fire-like environment, have your child practice escaping from his/her bedroom with eyes closed. As you practice the plan, reinforce the following safety tips along the way:</article>
                            <ul className="keep-as-list">
                                <li>Crawl low to avoid heat and smoke.</li>
                                <li>Feel doors with the back of your hand before opening them. Do not open the door if it feels hot - use your second exit get out fast.</li>
                                <li>Meet outside and then call 911 for help.</li>
                                <li>Stay outside no matter what - don't go back for anything.</li>
                            </ul>
                        </div>
                    </div>

                    <div id="images" className="col-lg-3 col-sm-12">
                        <figure className="figure">
                            <img src={process.env.PUBLIC_URL + `/images/Fire_and_safety6.jpg`} className="figure-img img-fluid rounded border shadow-sm" alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EscapePlanning;