import React from 'react';
import Phone from './Phone';
// import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer>
            <div className="container">
                <br />
                {/* <div className="row text-center">
                    <div className="col-lg-6 col-sm-12 col-xs-12col-xs-12">
                        <div><a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/search/?api=1&query=4350+Grapeview+Loop+Rd.+Grapeview%2C+WA+98546">4350 Grapeview Loop Rd.<br/>Grapeview, WA 98546</a></div>
                        
                        <div><span className="fw-bold">Tel:</span> <Phone number="(360) 275-4483" /> / <Phone number="(360) 427-6421" /></div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-xs-12col-xs-12">
                        <div>PO Box 129<br/>
                        Grapeview, WA 98546</div>
                        <div><span className="fw-bold">Fax:</span> <Phone number="360-275-8515" isFax={true} /><br/>
                        <a href="mailto:info@grapeviewfire.com">chiefg@wavecable.com</a><br />
                        <a href="mailto:info@grapeviewfire.com">info@grapeviewfire.com</a></div>
                    </div>
                </div> */}
                
                {/* <div className="row text-center">
                    <div className="col-12">
                    <ul className="list-inline">
                        <li className="list-inline-item">&#169; Mason County Fire District 3</li>
                        <li className="list-inline-item">|</li>
                        <li className="list-inline-item"><Link to="/contactus/">Contact Us</Link></li>
                    </ul>
                    </div>
                </div> */}
                
                <div className="row text-center">
                    <div className="col-lg-12 col-sm-12 col-xs-12 col-xs-12 mb-4">
                        <div>PO Box 1910 Shelton<br/>
                        Tel: <Phone number="360-426-5533" />/ <Phone number="360-275-4483" /><br/>
                        <a href="www.cmfe.org" target="_blank" rel="noopener noreferrer">www.cmfe.org</a><br />
                        <a href="mailto:info@cmfe.org">info@cmfe.org</a><br/>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;