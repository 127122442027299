import React from 'react';
import { Link } from 'react-router-dom';

function Breadcrumbs({ activePage }) {
    return (
        <nav className="mt-5" aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link className="breadcrumb-link" to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link className="breadcrumb-link" to="/fireandsafety">Fire and Safety</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{activePage}</li>
            </ol>
        </nav>
    );
}

export default Breadcrumbs;
