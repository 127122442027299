import React, { useContext, useEffect, useState } from "react";
// import publicRecReq from "../files/forms/public_records_request.doc";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../AppContext";

function MeetingMinutes() {
  const { pages, pagesAreLoading } = useContext(AppContext);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    const getPage = () => {
      if (pages) {
        let thisPage = {};
        pages.forEach((page, index) => {
          if (page && page.link.indexOf("agenda") > -1) {
            thisPage = page;
          }
        });
        setPageData(thisPage);
      }
    };

    if (pages) {
      getPage();
    }
  }, [pages]);

  if (pagesAreLoading) {
    return (
      <div id="agenda" className="content">
        <Container>
          {/* <ScrollToTopOnMount /> */}
          <Container className="page">
            {/* <Breadcrumbs activePage="Documents & Reports" /> */}
            <Row>
              <Col>
                <Container>
                  <h1 className="page-heading">Agenda</h1>
                  <Container className="main align-content-start">
                    <Container>
                      <div className="d-flex justify-content-center mt-5 mb-3">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </Container>
                  </Container>
                </Container>
              </Col>
              {/* <Col md={3}>
                    <Sidebar />
                  </Col> */}
            </Row>
          </Container>
          {/* <GoToTop /> */}
        </Container>
      </div>
    );
  }

  if (!pages) return null;

  function displayLinks() {
    return (
      <Container>
        {pageData && pageData.content && pageData.content.rendered ? (
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{
              __html: pageData.content.rendered,
            }}
          />
        ) : null}
      </Container>
    );
  }
  return (
    <div id="agenda" className="content">
      <div className="container">
        <h1 className="page-heading">Agenda</h1>
        <p>
          To comply with RCW 42.30.077, the agenda for the Fire Commissioner
          Board Meetings associated with the operation of Mason County Fire
          District No 3 are available below. To view them, click on the
          respective date.
        </p>
        <p>
          You will need to have Microsoft Word or Adobe Acrobat Reader installed
          on your computer to view the meeting minutes.
        </p>
        {/* <p>
          Attachments are not available online. To obtain copies of attachments,
          a request must be made in writing by completing a Public Records
          Request form. Once completed the form may be returned by mail, fax or
          in person to station 3-1 on Grapeview Loop Road.
        </p> */}

        {/* <p>
          <a
            href={publicRecReq}
            alt="Click here to download the public records request form"
          >
            Download the Public Records Request form{" "}
            <svg
              className="bi bi-download"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M.5 8a.5.5 0 01.5.5V12a1 1 0 001 1h12a1 1 0 001-1V8.5a.5.5 0 011 0V12a2 2 0 01-2 2H2a2 2 0 01-2-2V8.5A.5.5 0 01.5 8z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M5 7.5a.5.5 0 01.707 0L8 9.793 10.293 7.5a.5.5 0 11.707.707l-2.646 2.647a.5.5 0 01-.708 0L5 8.207A.5.5 0 015 7.5z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M8 1a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 1z"
                clipRule="evenodd"
              />
            </svg>
          </a>{" "}
          (DOC)
        </p> */}
        <div className="row">{displayLinks()}</div>
      </div>
    </div>
  );
}

export default MeetingMinutes;
