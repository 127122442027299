import React from "react";
import Phone from "./Phone";
import Email from "./Email";
// import ContactCard from "./ContactCard";
// import contacts from "../files/contacts.json";

function ContactUs() {
//   function displayContactCard(contact, index) {
//     return (
//       <ContactCard
//         key={`contact-${index}`}
//         name={contact.name}
//         address1={contact.address1}
//         address2={contact.address2}
//         phone={contact.phone}
//         email={contact.email}
//         url={contact.url}
//       />
//     );
//   }

  return (
    <div id="contact-us" className="content">
      <div className="container text-center">
        <h1 className="page-heading">Contact Us</h1>
        <div className="row mb-5">
        <div className="contact col-lg-12 col-sm-12">
          <h3 className="text-center">Mason County Fire District 3</h3>
          <br />
            PO Box 1910 Shelton
          <br />
            <Phone number="360-426-5533" /> / <Phone number="360-275-4483" />
            <br />
            <a href="www.cmfe.org" target="_blank" rel="noopener noreferrer">
              www.cmfe.org
            </a>
            <br />
            <Email email="info@cmfe.org" />
            </div>
        </div>
        {/* <div className="row">
                    <div className="contact col-lg-6 col-sm-12">
                        PO Box 129<br />
                        Grapeview, WA 98546
                    </div>
                    <div className="contact col-lg-6 col-sm-12">
                        Tel: <Phone number="360-275-4483" /> or <Phone number="360-427-6421" />
                    </div>
                </div>
                <div className="row">
                    <div className="contact col-lg-6 col-sm-12">
                        4350 Grapeview Loop Rd.<br />
                        Grapeview, WA 98546
                    </div>
                    <div className="contact col-lg-6 col-sm-12">
                        Fax: <Phone number="360-275-8515" isFax={true} /><br />
                        <Email email="chiefg@wavecable.com" /><br />
                        <Email email="info@grapeviewfire.com" />
                    </div>
                </div> */}
        {/* <div className="row">
          <h3>Other Mason County Fire Districts</h3>
        </div>
        <div className="row">{contacts.map(displayContactCard)}</div> */}
      </div>
    </div>
  );
}

export default ContactUs;
