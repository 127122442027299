import React from 'react';

const MissionStatement = () => {
    return (
        <div id="our-mission" className="col-lg-6 col-xs-12" style={{ minHeight: '160px'}}>
            <div className="card personalize shadow-sm">
                <div className="card-header">
                    <h3>Our Mission Statement</h3>
                </div>
                <div className="card-body">
                    <p className="card-text">"To ensure life safety and preserve property by providing quality service while maintaining community support and involvement through education and awareness."</p>
                </div>
            </div>
        </div>
    );
}

export default MissionStatement;