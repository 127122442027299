// import React, { useContext, useCallback } from 'react';
import React, { useCallback } from 'react';
import DailyInfoListItem from './DailyInfoListItem';
// import { AppContext } from '../../AppContext';
import dailyInfo from '../../files/covid/dailyinfo.json';
import Breadcrumbs from '../Covid19/Breadcrumbs';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';

const DailyInformation = () => {
    // const { dailyInfo } = useContext(AppContext);

    const goThroughDailyInfo = useCallback(() => {
        const months = ['September', 'August', 'July', 'June', 'May', 'April', 'March'];
        return months.map((month, index) => <DailyInfoListItem dailyInfo={dailyInfo} month={month} index={index} key={`daily-info-${month}-2020-${index}`} />);
    }, []);

    return (
        <div id="covid19" className="content">
            <div className="container">
                <Breadcrumbs activePage="Daily Information Releases" />
                <ScrollToTopOnMount />
                <h1 className="page-heading">COVID-19 Daily Information Releases</h1>
                <ul>
                    { goThroughDailyInfo() }
                </ul>
            </div>
        </div>
    );
};

export default DailyInformation;
