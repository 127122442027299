import React from 'react';
// import FireAndSafetyIntro from './FireAndSafetyIntro';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import Breadcrumbs from './Breadcrumbs';

function KitchenSafety() {
    return (
        <div id="kitchen-safety" className="content">
            <div className="container">
                <Breadcrumbs activePage="Kitchen Safety" />
                <ScrollToTopOnMount />
                {/* <FireAndSafetyIntro /> */}
                <h2 className="mt-3">Safety in the Kitchen</h2>
                <div className="row">
                    <div className="col-lg-9 col-sm-12">
                        <ul className="keep-as-list mt-3">
                            <li>Never leave cooking unattended. Turn off appliances as soon as you are done, and unplug electrical appliances when not in use.</li>
                            <li>Keep your stove-top and oven clean to prevent built-up grease from catching on fire.</li>
                            <li>Wear snug-fitting sleeves while cooking. Loose-fitting clothes can catch fire when they dangle near hot burners.</li>
                            <li>Keep dish towels, potholders, curtains and other flammables away from the stove.</li>
                            <li>Prevent burns by turning pot handles inward so they cannot be bumped or pulled over by a child.</li>
                            <li>Never allow children or pets to play in the area where you are cooking. Create a 3-foot “child/pet free” zone near the oven/stove.</li>
                            <li>Watch out for microwave burns. Use potholders to remove food, remove lids carefully to prevent steam burns, and test food temperature before eating.</li>
                            <li>Do not overload electrical outlets. Make sure your hands, and the floor you are standing on, are dry before handling electrical appliances.</li>
                        </ul>
                    </div>
                    <div id="images" className="col-lg-3 col-sm-12">
                        <figure className="figure">
                            <img src={process.env.PUBLIC_URL + `/images/fire_and_safety_kitchen_safety.jpg`} className="figure-img img-fluid rounded border shadow-sm" alt="" />
                        </figure>
                        <figure className="figure">
                            <img src={process.env.PUBLIC_URL + `/images/fire_and_safety_kitchen_safety_2.jpg`} className="figure-img img-fluid rounded border shadow-sm" alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KitchenSafety;