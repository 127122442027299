import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <div id="navigation">
      {/* Hide on screens smaller than lg */}
      <nav
        id="regular-screen"
        role="navigation"
        className="navbar navbar-expand-lg navbar-dark text-uppercase d-none d-lg-block"
      >
        <div className="fixed-inner">
          <div id="navright">
            {/* The burger button */}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse d-none d-lg-block"
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav me-auto mt-2 mt-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" to="/opportunities">
                    Job Opportunities
                  </Link>
                </li>
                {/* <li className="nav-item">
                        <Link className="nav-link" to="/hallrental">Hall Rental</Link>
                    </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/otherlinks">
                    Other Links
                  </Link>
                </li>
                {/* <li className="nav-item important">
                        <Link className="nav-link" to="/covid19">COVID-19</Link>
                    </li> */}
                {/* <li className="nav-item important">
                  <Link className="nav-link" to="/emslevyrenewal">
                    EMS Levy Renewal
                  </Link>
                </li> */}
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#/covid19"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    COVID-19
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link className="dropdown-item" to="/covid19">
                      Information
                    </Link>
                    <Link className="dropdown-item" to="/covid19/resources">
                      Additional Resources
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/covid19/dailyinformation"
                    >
                      Daily Information Releases
                    </Link>
                    <Link className="dropdown-item" to="/covid19/pressrelease">
                      Press Releases
                    </Link>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
          <div id="navleft" className="">
            {/* <Link to="/home" className="navbar-brand" href="#">Grapeview<span className="visually-hidden">(current)</span></Link> */}
            <div
              className="collapse navbar-collapse d-none d-lg-block"
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav me-auto mt-2 mt-lg-0">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#/ourdistrict"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    About Us
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    {/* <Link className="dropdown-item" to="/firechief">
                      Fire Chief
                    </Link> */}
                    <Link className="dropdown-item" to="/ourdistrict">
                      Our District
                    </Link>
                    <Link className="dropdown-item" to="/contactus">
                      Contact Us
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#/ourdistrict"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Commissioners
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link className="dropdown-item" to="/commissioners">
                      Commissioner's Information
                    </Link>
                    <Link className="dropdown-item" to="/budgets">
                      Budgets
                    </Link>
                    <Link className="dropdown-item" to="/agenda">
                      Agenda
                    </Link>
                    <Link className="dropdown-item" to="/meetingminutes">
                      Meeting Minutes
                    </Link>
                    {/* <Link className="dropdown-item" to="/events">
                      Events
                    </Link> */}
                    {/* <Link className="dropdown-item" to="/zoomhelp">
                      Zoom Meeting Help
                    </Link> */}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#/fireandsafety"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Safety & Community
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link className="dropdown-item" to="/fireandsafety">
                      Fire and Safety
                    </Link>
                    {/* <Link className="dropdown-item" to="/covid19">
                      COVID-19
                    </Link> */}
                    {/* <Link className="dropdown-item" to="/covid19/resources">
                      COVID-19 Additional Resources
                    </Link> */}
                    {/* <Link className="dropdown-item" to="/events">
                      Events
                    </Link> */}
                    <Link className="dropdown-item" to="/hallrental">
                      Hall Rental
                    </Link>
                    <Link className="dropdown-item" to="/gallery">
                      Gallery
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {/* Hide on screens wider than lg */}
      <nav
        id="not-large-screen"
        className="navbar navbar-expand-lg navbar-dark text-uppercase d-lg-none ps-0 pe-0"
      >
        <Link to="/home" className="navbar-brand" href="#">
          Grapeview<span className="visually-hidden">(current)</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav mt-2 mt-lg-0">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#/ourdistrict"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                About Us
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Link className="dropdown-item" to="/firechief">
                  Fire Chief
                </Link>
                <Link className="dropdown-item" to="/ourdistrict">
                  Our District
                </Link>
                <Link className="dropdown-item" to="/contactus">
                  Contact Us
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#/ourdistrict"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Commissioners
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Link className="dropdown-item" to="/commissioners">
                  Commissioner's Information
                </Link>
                <Link className="dropdown-item" to="/agenda">
                  Agenda
                </Link>
                <Link className="dropdown-item" to="/meetingminutes">
                  Meeting Minutes
                </Link>
                {/* <Link className="dropdown-item" to="/events">
                  Events
                </Link> */}
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#/fireandsafety"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Safety & Community
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Link className="dropdown-item" to="/fireandsafety">
                  Fire and Safety
                </Link>
                {/* <Link className="dropdown-item" to="/covid19">
                  COVID-19
                </Link> */}
                {/* <Link className="dropdown-item" to="/covid19/resources">
                  COVID-19 Additional Resources
                </Link> */}
                {/* <Link className="dropdown-item" to="/events">
                  Events
                </Link> */}
                <Link className="dropdown-item" to="/hallrental">
                  Hall Rental
                </Link>
                <Link className="dropdown-item" to="/gallery">
                  Gallery
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/opportunities">
                Job Opportunities
              </Link>
            </li>
            {/* <li className="nav-item">
                    <Link className="nav-link" to="/hallrental">Hall Rental</Link>
                </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/otherlinks">
                Other Links
              </Link>
            </li>
            {/* <li className="nav-item important">
              <Link className="nav-link" to="/emslevyrenewal">
                EMS Levy Renewal
              </Link>
            </li> */}
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#/covid19"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                COVID-19
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Link className="dropdown-item" to="/covid19">
                  Information
                </Link>
                <Link className="dropdown-item" to="/covid19/resources">
                  Additional Resources
                </Link>
                <Link className="dropdown-item" to="/covid19/dailyinformation">
                  Daily Information Releases
                </Link>
                <Link className="dropdown-item" to="/covid19/pressrelease">
                  Press Releases
                </Link>
              </div>
            </li> */}
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
