import React from "react";
import OpportunitiesDynamic from "./Dynamic/OpportunitiesDynamic.js";

function Opportunities() {
  return (
    <div id="opportunities" className="content">
      <div className="container">
        <h1 className="page-heading">Job Opportunities</h1>
        <OpportunitiesDynamic />
      </div>
    </div>
  );
}

export default Opportunities;
