import React from 'react';
// import FireAndSafetyIntro from './FireAndSafetyIntro';
import ImageLink from '../ImageLink';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import Breadcrumbs from './Breadcrumbs';
// import { Link } from 'react-router-dom';

function CprClasses() {
    return (

        <div id="cpr-classes" className="content">
            <div className="container mt-5 mt-md-0">
                <Breadcrumbs activePage="CPR Classes" />
                <ScrollToTopOnMount />
                {/* <FireAndSafetyIntro /> */}
                <h2 className="mt-4">CPR Classes</h2>
                <div className="row p-1">
                    <div className="col-lg-9 col-sm-12">
                        <article className="mb-2">
                            Mason General Hospital in Shelton, as well as Harrison Medical Center currently offer CPR training. Click on the links below to be re-directed to their websites.
                    </article>

                        <ul className="keep-as-list mt-3">
                            <li><a target="_blank" rel="noopener noreferrer" href="https://www.masongeneral.com/community/classes/mgh-fc-offers-community-first-aid-cpr-aed-course-in-july" alt="Link to the Mason General Hospital website">Mason General Hospital</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://events.chifranciscan.org/classes/find" alt="Link to the Harrison Medical Center website">Harrison Medical Center</a></li>
                        </ul>

                        <h5>Why Should I Learn CPR?</h5>
                        <ul className="keep-as-list mt-3">
                            <li>CPR doubles a person’s chance of survival from cardiac arrest.</li>
                            <li>650,000 people die annually from heart attacks in the U.S.</li>
                            <li>350,000 people die before reaching the hospital</li>
                            <li>4 to 6 minutes without oxygen results in brain damage or brain death</li>
                            <li>If CPR is started within 4 minutes of collapse and defibrillation provided within 10 minutes, a person has a 40% chance of survival.</li>
                            <li>90% of the time, CPR will be performed on a family member or a close friend.</li>
                        </ul>

                        <h5 className="mt-3">Be a Lifesaver!</h5>

                        <article className="mb-3">It’s safe, And it’s easy. If you witness a sudden unexpected collapse in an adult..</article>
                    </div>
                    <div id="images" className="col-lg-3 col-sm-12">
                        <figure className="figure">
                            <ImageLink file="images/fire_and_safety_CPR2.jpg" classes="figure-img img-fluid rounded border" alt="TBD" />
                        </figure>
                    </div>
                </div>
                {/* <div className="row justify-content-center mt-4">
                    <Link to="/fireandsafety"><i className="fa fa-caret-left" aria-hidden="true"></i> Go back to Fire and Safety</Link>
                </div> */}
            </div>
        </div>
    );
}

export default CprClasses;