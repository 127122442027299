import React from 'react';

function WelcomeMessage() {
    return (
        <div>
            <h1 className="display-4">Welcome</h1>
            <hr />
            <p className="lead">The mission of the Fire Department is to protect the lives and property of the people of Grapeview from fires, natural disasters, and hazardous materials incidents; to save lives by providing emergency medical services; to prevent fires through prevention and education programs; and to provide a work environment that values health, wellness and cultural diversity and is free of harassment and discrimination.</p>
            <p id="notice" className="font-italic"><small className="text-secondary">This site was established to provide information about Mason County Fire District 3. On this site you will find valuable information about home and personal fire safety, as well as other fire related information as it pertains to Mason County.</small></p>
        </div>
    );
}

export default WelcomeMessage;
