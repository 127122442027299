
import React from 'react';

// This only works with documents in the public folder
// File paths should not start with a forward slash
// File paths should be relative to public/
function DocumentLink(props) {
    const filepath = props.file.trim();
    const altText = (props.alt && props.alt.trim()) || '';
    const displayText = (props.display && props.display.trim()) || '';
    const link = <a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + `/` + filepath} alt={altText}>{displayText}</a>;
    return link;
}

export default DocumentLink;