import React from 'react';
import { Link } from 'react-router-dom';
// import Phone from '../Phone';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import ImageLink from '../ImageLink';

function ZoomHelp() {
    return (
        <div id="zoom-help" className="content">
            <div className="container">
                <ScrollToTopOnMount />
                <h1 className="page-heading">Need help with Zoom?</h1>

                <div className="mt-5 mb-5 alert alert-warning" role="alert">
                    <strong>Zoom</strong> is a cloud-based video conferencing service you can use to virtually meet with others either by video or audio-only or both. Grapeview Fire meetings are currently being held via Zoom in light of COVID-19 restrictions. If you need help with joining thesez Zoom meetings, continue reading.
                </div>

                <div className="row">
                    <div className="col-12">
                        <h3 className="mt-3 mb-4">To join a Grapeview Fire Zoom meeting, there are a few details that you should take note of:</h3>
                        <section className="mb-5">
                            <h5 className="mt-4">
                                The detailed information for Grapeview Fire Zoom meetings will normally provide:
                            </h5>

                            <ol>
                                <li className="mb-1">a <strong>Website</strong> link</li>
                                <li className="mb-1">a <strong>Meeting ID</strong></li>
                                <li className="mb-1">a <strong>Passcode</strong> (if necessary)</li>
                                <li className="mb-1">a <strong>phone number</strong> for you to dial by your location (or a link for you to find your local number)</li>
                            </ol>

                            <h5 className="mt-4">To find the above information, <Link to="/events">go to the Events page</Link> and do either of the following:</h5>

                            <ol>
                                <li className="mb-1">
                                    <p className="mb-3">Click on the meeting you intend to join, and look for the information listed above (website, meeting ID, passcode, phone number).</p>
                                    
                                    
                                    <div className="images container">
                                        <div className="row">
                                            <div className="col-lg-6 col-12">
                                                <ImageLink file="files/zoom/EventsCalendar1.jpg" classes="figure-img img-fluid rounded mx-auto d-block mb-2 border border-secondary" alt="" />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <ImageLink file="files/zoom/EventsCalendar2.jpg" classes="figure-img img-fluid rounded mx-auto d-block mb-2 border border-secondary" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <p className="h3 text-center mt-5 mb-5"><strong>OR</strong></p>

                                <li className="mb-1">
                                    <p className="mb-3">Click the "Show all events" button above the calendar, and look for the information listed above (website, meeting ID, passcode, phone number).</p>
                                    
                                    <div className="images container">
                                        <div className="row">
                                            <div className="col-lg-6 col-12">
                                                <ImageLink file="files/zoom/EventShowAllEvents.jpg" classes="figure-img img-fluid rounded mx-auto d-block mb-2 border border-secondary" alt="" />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <ImageLink file="files/zoom/EventShowAllEvents2.jpg" classes="figure-img img-fluid rounded mx-auto d-block mb-2 border border-secondary" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </section>

                        <hr className="mt-5 mb-5"/>

                        <section className="mt-5 mb-5">
                            <h3 className="mt-5 mb-3">You can join a Zoom meeting through a variety of ways.</h3>

                            <h5 className="mb-3">Option 1: Join on your computer</h5>
                            
                            <p>To join a Zoom meeting on your computer, first, you will have to download the Zoom application from <a href="https://zoom.us/download#client_4meeting" target="_blank" rel="noopener noreferrer">https://zoom.us/download#client_4meeting <i className="fa fa-external-link fa-sm" aria-hidden="true"></i></a>.</p>

                            <p className="mb-3">Then to join a Zoom meeting, you can do one of the following:</p>
                            <ul>
                                <li className="mb-1">
                                    <h6>Option 1: Go to <a href="https://grapeviewfire.com/events" target="_blank" rel="noopener noreferrer">https://grapeviewfire.com/events <i className="fa fa-external-link fa-sm" aria-hidden="true"></i></a> and click on the meeting link for the meeting you intend to join. The link will be the "Website" link and will look like <strong>https://us02web.zoom.us/j/89197437849?pwd=ZU5kTEJvakpwWXNFM2JlYll4RVQrUT09</strong>. Confirm if you're prompted to open the Zoom application to launch it. If the Zoom application is successfully opened, you should then be connected to the meeting.</h6>
                                    <p className="mb-3">
                                    
                                    </p>
                                    <ImageLink file="files/zoom/EventsCalendar.jpg" classes="figure-img img-fluid rounded w-75 mx-auto d-block mb-5 border border-secondary" alt="" />
                                </li>

                                <p className="h4 text-center mt-5 mb-5"><strong>OR</strong></p>

                                <li className="mb-1">
                                    <h6>Option 2: Go to <a href="https://join.zoom.us/" target="_blank" rel="noopener noreferrer">https://join.zoom.us/ <i className="fa fa-external-link fa-sm" aria-hidden="true"></i></a> and enter the Meeting ID provided and click "Join". Confirm if you're prompted to open the Zoom application to launch it. If the Zoom application is successfully opened, you should then be connected to the meeting.</h6>
                                    <ImageLink file="files/zoom/ZoomJoin.jpg" classes="figure-img img-fluid rounded w-75 mx-auto d-block mb-5 border border-secondary" alt="" />
                                </li>

                                <p className="h4 text-center mt-5 mb-5"><strong>OR</strong></p>

                                <li className="mb-1">
                                    <h6>Option 3: Open the Zoom application on your computer.</h6>
                                    <ul>
                                        <li className="mb-1">
                                            <p>Enter the Meeting ID and your name, then click Join.</p>
                                        </li>
                                        <li className="mb-1">
                                            <p>Enter the Meeting Passcode if prompted, then click Join.</p>
                                            <div className="images container">
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                    <ImageLink file="files/zoom/ZoomMeetingID.jpg" classes="figure-img img-fluid rounded m-1 mb-5 border border-secondary" alt="" />
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                    <ImageLink file="files/zoom/ZoomPasscode.jpg" classes="figure-img img-fluid rounded m-1 mb-5 border border-secondary" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mb-1">Confirm if you're prompted to open the Zoom application to launch it.</li>
                                        <li className="mb-1">If the Zoom application is successfully opened, you should then be connected to the meeting.</li>
                                    </ul>
                                </li>
                            </ul>

                            <hr className="mt-5 mb-5"/>

                            <h5 className="mb-3">Option 2: Join on your phone or tablet/iPad via the Zoom mobile application</h5>

                            <p>You can also join a Zoom meeting by downloading the Zoom mobile application on your phone. The pictures below show the Zoom application on an iPhone, but other mobile platforms like Android should look similar.</p>

                            <p className="mb-3">Once you have the Zoom application downloaded:</p>
                            <ol>
                                <li className="mb-1">
                                    <p>Press "Join a Meeting".</p>
                                </li>
                                <li className="mb-1">
                                    <p>Enter the Meeting ID and your name then press "Join".</p>
                                </li>
                                <li className="mb-1">
                                    <p>If prompted, input the passcode provided, then press "Continue".</p>
                                    <div className="text-center">
                                    
                                    
                                    

                                    <div className="images container">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <ImageLink file="files/zoom/ZoomMobile1.PNG" classes="figure-img img-fluid rounded mx-auto mb-5 p-2 border border-secondary" alt="" />
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <ImageLink file="files/zoom/ZoomMobile2.PNG" classes="figure-img img-fluid rounded mx-auto mb-5 p-2 border border-secondary" alt="" />
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <ImageLink file="files/zoom/ZoomMobile3.PNG" classes="figure-img img-fluid rounded mx-auto mb-5 p-2 border border-secondary" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </li>
                                <li className="mb-1">If the Zoom application is successfully opened, you should then be connected to the meeting.</li>
                            </ol>

                            <hr className="mt-5 mb-5"/>

                            <h5 className="mb-3">Option 3: Join on your phone by dialing in</h5>

                            <p>Another option for joining a Zoom meeting is by dialing in from your phone.</p>
                            
                            <p className="mb-3">Dial the phone number provided on the meeting detail and enter the Meeting ID number and Passcode (if necessary) when prompted using your dial pad.</p>

                            <ImageLink file="files/zoom/EventsCalendarPhone.jpg" classes="figure-img img-fluid rounded w-75 mx-auto d-block border border-secondary" alt="" />
                        </section>
                        
                        <hr className="mt-5 mb-5"/>

                        <p className="mt-3">
                        More details about Zoom can be found here: <a href="https://support.zoom.us/hc/en-us/categories/200101697" target="_blank" rel="noopener noreferrer">https://support.zoom.us/hc/en-us/categories/200101697</a>.
                        </p>
                        <p>
                        More details about joining Zoom meetings can be found here: <a href="https://support.zoom.us/hc/en-us/articles/201362193-Joining-a-meeting" target="_blank" rel="noopener noreferrer">https://support.zoom.us/hc/en-us/articles/201362193-Joining-a-meeting</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ZoomHelp;