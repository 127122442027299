import React from "react";
import { Link, Routes, Route } from "react-router-dom";
import oldstructurefire3 from "../images/oldstructurefire3.JPG";
import DocumentLink from "./DocumentLink";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
// import BurnBan from './BurnBan';
import EscapePlanning from "./FireAndSafety/EscapePlanning";
import WaterSafety from "./FireAndSafety/WaterSafety";
import KitchenSafety from "./FireAndSafety/KitchenSafety";
import SmokeAlarms from "./FireAndSafety/SmokeAlarms";
import SmokeDetectorTips from "./FireAndSafety/SmokeDetectorTips";
import CprClasses from "./FireAndSafety/CprClasses";
import OutdoorBurning from "./FireAndSafety/OutdoorBurning";
import HomeFireSafety from "./FireAndSafety/HomeFireSafety";

function FireAndSafety() {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <div id="fire-and-safety" className="content">
            <ScrollToTopOnMount />
            <div className="container">
              <nav className="mt-5" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link className="breadcrumb-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Fire and Safety
                  </li>
                </ol>
              </nav>
              <h1 className="page-heading">Fire and Safety</h1>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <p className="lead">
                    The Grapeview Fire Department strives to keep our residents
                    and visitors safe, and the best way to avoid a dangerous
                    situation is through prevention and education. We hope these
                    safety tips, programs and educational opportunities help you
                    in your safety efforts.
                  </p>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <img
                    src={oldstructurefire3}
                    className="border rounded-3 img-fluid shadow-sm"
                    alt="A structure fire"
                  />
                </div>
              </div>
              <div className="row align-items-start mt-3">
                <div className="col-lg-4 col-sm-12">
                  <h2>Fire & Water Safety</h2>
                  <ul>
                    <li>
                      <Link to="/fireandsafety/homefiresafety">
                        Home Fire Safety
                      </Link>
                    </li>
                    <li>
                      <Link to="/fireandsafety/escapeplanning">
                        Fire Escape Planning
                      </Link>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          process.env.PUBLIC_URL +
                          `/documents/rural_fire_prevention_checklist.pdf`
                        }
                        alt="Link to download the Rural Fire Prevention Checklist pdf"
                      >
                        Rural Fire Prevention Checklist
                      </a>{" "}
                      (PDF)
                    </li>
                    <li>
                      <Link to="/fireandsafety/watersafety">Water Safety</Link>
                    </li>
                    <li>
                      <Link to="/fireandsafety/kitchensafety">
                        Kitchen Safety
                      </Link>
                    </li>
                    <li>
                      <Link to="/fireandsafety/smokedetectortips">
                        Smoke Detector Tips
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <h2>Games & Activities</h2>

                  <ul>
                    <li>
                      <DocumentLink
                        alt="Link to download the Fire Safety Activity Book PDF file"
                        file="documents/activity_book.pdf"
                        display="Fire Safety Activity Book"
                      />{" "}
                      (PDF)
                    </li>
                    <li>
                      <DocumentLink
                        file="documents/coloringbook.pdf"
                        alt="Link to download the Fire Safety Coloring Book PDF file"
                        display="Fire Safety Coloring Book"
                      />{" "}
                      (PDF)
                    </li>
                    <li>
                      <DocumentLink
                        file="documents/fire_crossword.pdf"
                        alt="Link to download the Fire Safety Crossword PDF file"
                        display="Fire Safety Crossword"
                      />{" "}
                      (PDF)
                    </li>
                    <li>
                      <DocumentLink
                        file="documents/fire_vocab_sheet.pdf"
                        alt="Link to download the Fire Safety Vocabulary Worksheet PDF file"
                        display="Fire Safety Vocabulary Worksheet"
                      />{" "}
                      (PDF)
                    </li>
                    <li>
                      <DocumentLink
                        file="documents/fire_word_search.pdf"
                        alt="Link to download the Fire Safety Word Search PDF file"
                        display="Fire Safety Word Search"
                      />{" "}
                      (PDF)
                    </li>
                    <li>
                      <DocumentLink
                        file="documents/maze.pdf"
                        alt="Link to download the Fire Safety Maze PDF file"
                        display="Fire Safety Maze"
                      />{" "}
                      (PDF)
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <h2>Other Education & Information</h2>
                  <ul>
                    <li>
                      {/* <Link to="/fireandsafety/cprclasses">CPR Classes</Link> */}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href='https://cmfe.org/services/cpr-first-aid'
                        alt="CPR Classes"
                      >
                        CPR Classes
                      </a>
                    </li>
                    <li>
                      {/* <Link to="/fireandsafety/outdoorburning">
                        Outdoor Burning Regulations
                      </Link> */}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href='https://cmfe.org/community/burnrestrictions'
                        alt="Outdoor Burning Regulations"
                      >
                        Outdoor Burning Regulations
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <Route
        path="/fireandsafety/homefiresafety"
        element={<HomeFireSafety />}
      />
      <Route
        path="/fireandsafety/escapeplanning"
        element={<EscapePlanning />}
      />
      <Route path="/fireandsafety/watersafety" element={WaterSafety} />
      <Route path="/fireandsafety/kitchensafety" element={KitchenSafety} />
      <Route
        path="/fireandsafety/smokedetectortips"
        element={SmokeDetectorTips}
      />
      <Route path="/fireandsafety/smokealarms" element={SmokeAlarms} />
      <Route path="/fireandsafety/cprclasses" element={CprClasses} />
      <Route path="/fireandsafety/outdoorburning" element={OutdoorBurning} />
    </Routes>
  );
}

export default FireAndSafety;
