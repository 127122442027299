import React from 'react';

const SchoolsLinks = () => {
    return (
        <>
            <h5 className="mt-3">Schools</h5>
            <ul>
                <li><a href="https://www.sheltonschools.org/departments/health_wellness/coronavirus_information" target="_blank" rel="noopener noreferrer">Shelton School District</a></li>
                <li><a href="https://www.olympic.edu/about-olympic-college/covid-19-coronavirus-information" target="_blank" rel="noopener noreferrer">Olympic College</a></li>
                <li><a href="https://www.hoodcanal.wednet.edu/" target="_blank" rel="noopener noreferrer">Hood Canal School District</a></li>
                <li><a href="https://psd402.org/news/what_s_new/c_o_v_i_d-19___coronavirus__information" target="_blank" rel="noopener noreferrer">Pioneer School District</a></li>
                <li><a href="https://www.marymknight.com/" target="_blank" rel="noopener noreferrer">Mary M. Knight School District</a></li>
                <li><a href="https://southsideschool.org/for-families/health/" target="_blank" rel="noopener noreferrer">Southside School District</a></li>
                <li><a href="https://www.nmsd.wednet.edu/" target="_blank" rel="noopener noreferrer">North Mason School District</a></li>
                <li><a href="https://www.gsd54.org/" target="_blank" rel="noopener noreferrer">Grapeview School District</a></li>
                <li><a href="http://masoncountychristianschool.org/" target="_blank" rel="noopener noreferrer">Mason County Christian School</a></li>
            </ul>
        </>
    );
};

export default SchoolsLinks;
