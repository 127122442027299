import React from 'react';
import { Link } from "react-router-dom";

const BurnBanAlert = ({ isLifted, displayMoreInfo }) => {
    const displayAlertIcon = (size) => (
        <svg width={`${size}`} height={`${size}`} viewBox="0 0 16 16" className="bi bi-exclamation-triangle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
        </svg>
    );

    const lifted = <h4 className="alert-heading mb-0">Mason County burning restrictions have been lifted!</h4>;

    const liftedLearnMore = (
        <div className="d-flex justify-content-center">
            <div className="pe-2">
                {displayAlertIcon('3.5em')}
            </div>
            <div className="">
                <h4 className="alert-heading mb-0">Mason County burning restrictions have been lifted!</h4>
                <Link to="/fireandsafety/burnban" className="shadow-sm align-middle mb-1"><span className="btn btn-light btn-sm shadow-sm px-1 py-1 mt-1">Learn more...</span></Link>
            </div>
        </div>
);
    
    const inEffectLearnMore = (
            <div className="d-flex justify-content-center">
                <div className="pe-2">
                    {displayAlertIcon('3.5em')}
                </div>
                <div className="">
                    <h4 className="alert-heading mb-0">County-wide recreational burn ban is in effect as of <strong className="important">Saturday, September 10, 2022, 00:01 AM until further notice.</strong>.</h4>

                    <Link to="/fireandsafety/burnban" className="shadow-sm align-middle mb-1"><span className="btn btn-light btn-sm shadow-sm px-1 py-1 mt-1">Learn more...</span></Link>
                </div>
            </div>
    );

    const inEffect = (
        <div className="d-flex justify-content-center">
            <div className="pe-2">
                {displayAlertIcon('2em')}
            </div>
            <div className="">
                <h4 className="alert-heading mb-0">County-wide recreational burn ban is in effect as of Saturday, September 10, 2022, 00:01 AM until further notice.</h4>
            </div>
        </div>
    );

    return (
        <div id="burn-ban-alert">
            {isLifted ? (
                <div id="lifted" className="alert alert-warning text-center pt-3 pb-3 mx-auto shadow-sm" role="alert">
                    {displayMoreInfo ? liftedLearnMore : lifted}
                </div>
            ) :
            (
                <div id="in-effect" className="alert alert-warning text-center pt-3 pb-3 mx-auto shadow-sm" role="alert">
                    {displayMoreInfo ? inEffectLearnMore : inEffect}
                </div>
            )}
        </div>
    );
};

export default BurnBanAlert;