import React from 'react';
// import hallRentalApp from '../files/forms/Form1-201A9-16-08.doc';
import hallRentalApp from '../files/forms/Application-for-Meeting-Room.pdf';
// import events2 from '../images/events-2.JPG';
import Phone from './Phone';

function HallRental() {
    return (
        <div id="hall-rental" className="content">
            <div className="container">
                <h1 className="page-heading">Fire Hall Rental</h1>
                <div className="row">
                    <div className="col">
                        <p>The Grapeview Fire Department Meeting Hall offers a host of different opportunities for the public to rent our hall. Our recently remodeled fire hall is available for almost any function imaginable, from weddings to business meetings.</p>

                        <p>The Hall boasts a <strong>capacity of 176</strong>, and <strong>features a full service warming kitchen</strong>.</p>

                        <p>Any interested party wishing to view or rent the fire hall for your memorable occasions or business events, please feel free to contact our office <strong>Monday - Friday</strong> between the hours of <strong>9:00am - 4:00pm</strong> to arrange a time to come by. Dates are by availability on a first come, first serve basis.</p>

                        <p>A <strong>$250.00 security deposit</strong> is required, per the Rental Fee Schedule, and the rental fee is required to reserve the dates & times. All hall rental applications are subject to approval and date availability. The security deposit shall be returned to the lessee fourteen days after the function, pending no damages are found to the facility or extra cleaning is required. Also, liability insurance is required to rent the meeting hall. Inquire at our local station for additional information.</p>
                    </div>
                    {/* <div id="images" className="col-lg-6 col-sm-12 d-flex align-items-center">
                        <img src={events2} className="border rounded-3 img-fluid shadow-sm" alt="An event outside the Grapeview Fire Department Hall" />
                    </div> */}
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <table id="hall-rental-fees" className="table shadow-sm">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Rental Fee Schedule</th>
                                    <th scope="col"></th>
                                    <th scope="col">Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Small Groups</th>
                                    <td>25 or less</td>
                                    <td>$35.00</td>
                                </tr>
                                <tr>
                                    <th scope="row"></th>
                                    <td>26 to 50</td>
                                    <td>$75.00</td>
                                </tr>
                                <tr>
                                    <th scope="row">Large Groups</th>
                                    <td>51-100</td>
                                    <td>$175.00</td>
                                </tr>
                                <tr>
                                    <th scope="row"></th>
                                    <td>101+</td>
                                    <td>$225.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <section className='p-3'>
                        <h5><u>Rental Availability</u></h5>
                        <p>10 a.m. - 10 p.m.</p>

                        <h5><u>Large Groups</u></h5>
                        <p>Damage and Facility Cleaning Deposit: $250.00</p>

                        <h5><u>Note</u></h5>
                        <p><b>An insurance certificate of coverage is required to be on file with the district.</b></p>
                        
                        <p>Mason County District 3 Fire Commissioners reserve the right to adjust the fee schedule for special situations.</p>
                        </section>

                        <p>Feel free to call our fire department with any questions, <Phone number="360-275-4483" /></p>

                        <p>
                        <a href={hallRentalApp} alt="Click here to download the Hall Rental Application form" target="_blank" rel="noopener noreferrer" >
                            Download and print a Hall Rental Application <svg className="bi bi-download" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M.5 8a.5.5 0 01.5.5V12a1 1 0 001 1h12a1 1 0 001-1V8.5a.5.5 0 011 0V12a2 2 0 01-2 2H2a2 2 0 01-2-2V8.5A.5.5 0 01.5 8z" clipRule="evenodd" />
                            <path fillRule="evenodd" d="M5 7.5a.5.5 0 01.707 0L8 9.793 10.293 7.5a.5.5 0 11.707.707l-2.646 2.647a.5.5 0 01-.708 0L5 8.207A.5.5 0 015 7.5z" clipRule="evenodd" />
                            <path fillRule="evenodd" d="M8 1a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 1z" clipRule="evenodd" />
                        </svg></a> (PDF)</p>
                        <p>
                        <a href={process.env.PUBLIC_URL + `/documents/hallrental/District_Facilities_Rental_Procedures.pdf`} alt="Click here to download the District Facilities Rental Procedure PDF" target="_blank" rel="noopener noreferrer" >
                            Download and print the District Facilities Rental Procedures document
 <svg className="bi bi-download" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M.5 8a.5.5 0 01.5.5V12a1 1 0 001 1h12a1 1 0 001-1V8.5a.5.5 0 011 0V12a2 2 0 01-2 2H2a2 2 0 01-2-2V8.5A.5.5 0 01.5 8z" clipRule="evenodd" />
                            <path fillRule="evenodd" d="M5 7.5a.5.5 0 01.707 0L8 9.793 10.293 7.5a.5.5 0 11.707.707l-2.646 2.647a.5.5 0 01-.708 0L5 8.207A.5.5 0 015 7.5z" clipRule="evenodd" />
                            <path fillRule="evenodd" d="M8 1a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 1z" clipRule="evenodd" />
                        </svg></a> (PDF)</p>
                        <p>
                        <a href={process.env.PUBLIC_URL + `/documents/hallrental/District_Facilities_Public_Use_Policy.pdf`} alt="Click here to download the Hall Rental Application form" target="_blank" rel="noopener noreferrer" >
                            Download and print the District Facilities Public Use Policy document <svg className="bi bi-download" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M.5 8a.5.5 0 01.5.5V12a1 1 0 001 1h12a1 1 0 001-1V8.5a.5.5 0 011 0V12a2 2 0 01-2 2H2a2 2 0 01-2-2V8.5A.5.5 0 01.5 8z" clipRule="evenodd" />
                            <path fillRule="evenodd" d="M5 7.5a.5.5 0 01.707 0L8 9.793 10.293 7.5a.5.5 0 11.707.707l-2.646 2.647a.5.5 0 01-.708 0L5 8.207A.5.5 0 015 7.5z" clipRule="evenodd" />
                            <path fillRule="evenodd" d="M8 1a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 1z" clipRule="evenodd" />
                        </svg></a> (PDF)</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HallRental;