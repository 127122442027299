import React, { useState } from "react";
import { Link } from "react-router-dom";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import MyCalendar from "./Events/MyCalendar";
import AllEvents from "./Events/AllEvents";

function Events() {
  const [showPrintable, setShowPrintable] = useState(false);

  const togglePrintable = () => {
    setShowPrintable(!showPrintable);
  };

  return (
    <div id="events" className="content-no-padding">
      <ScrollToTopOnMount />
      <h1 className="page-heading">Events</h1>
      <div className="container">
        {/* <div className="alert alert-primary text-center pt-1 pb-1" role="alert">
                      <h4 className="alert-heading mb-0">All events are postponed indefinitely as of April 2, 2020.</h4>
                  </div> */}
        <hr className="ms-3 me-3" />

        <div className="alert alert-info" role="alert">
          <h6 className="mb-3">REGULAR MEETING ZOOM INFO</h6>
          <p>* Occurs every 3rd Wednesday of each month</p>
          {/* <div> */}
          <strong>Meeting ID:</strong> 810-9463-1180
          <br />
          <strong>Meeting link:</strong>{" "}
          <a
            href="https://us02web.zoom.us/j/81094631180"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://us02web.zoom.us/j/81094631180
          </a>
          <br />
          <strong>Dial by your location:</strong> +1 253 215 8782 US (Tacoma)
          <br />
          <strong>Find your local number:</strong>{" "}
          <a
            href="https://us02web.zoom.us/u/kbjcCbOi4c"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://us02web.zoom.us/u/kbjcCbOi4c
          </a>
          <br />
          <br />
          <p>
            <strong>
              NOTE: For Special Meetings (labeled as such), the Zoom meeting
              information might be different, so please verify the information
              that you are using.
            </strong>
          </p>
        </div>
        <div className="row mb-3 g-0 justify-content-between">
          <div className="col-lg-12 col-sm-12">
            <i
              className="fa fa-question-circle small pe-1"
              aria-hidden="true"
            />{" "}
            <Link to="/zoomhelp" className="text-center small mt-2">
              Need help joining Zoom meetings?
            </Link>
            {!showPrintable ? (
              <button
                type="button"
                className="btn btn-dark float-end shadow-sm"
                onClick={() => togglePrintable()}
              >
                Show all events
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-dark float-end shadow-sm"
                onClick={() => togglePrintable()}
              >
                Show calendar
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            {!showPrintable ? <MyCalendar /> : null}
            {showPrintable ? <AllEvents /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
