import React, { useCallback } from 'react';
import PressReleaseLink from './PressReleaseLink';
import monthConverter from '../../utils/monthConverter';

const PressReleaseListItem = ({ positiveInfoList, month}) => {
    const displayPositiveInfo = useCallback((month, file, index, totalCount) => {
        return <PressReleaseLink
            month={month}
            altText={'Positive COVID-19 information release for'}
            fileName={file}
            startIndex={9}
            index={index}
            totalCount={totalCount}
            key={`press-release-list-item-link-${month}-2020-${index}`}
            />
    }, []);

    return (
        <li key={`positive-${month}-2020`} className="mb-1">
            <h5 className="mb-0">{month} 2020</h5>
            <ul className="fa-ul">
            { positiveInfoList[month].map((file, index) => {
                const fileName = `positive-${monthConverter(month)}-${file}-2020.pdf`;
                return displayPositiveInfo(month, fileName, index, positiveInfoList[month].length); })
            }
            </ul>
        </li>
    );
};

export default PressReleaseListItem;
