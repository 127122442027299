import React from 'react';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import { Link } from 'react-router-dom';
import BurnBanDynamic from '../Dynamic/BurnBanDynamic';

const BurnBan = () => {
    return (
        <div id="outdoor-burning" className="content">
            <div className="container">
                <nav className="mt-5" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link className="breadcrumb-link" to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link className="breadcrumb-link" to="/fireandsafety">Fire and Safety</Link></li>
                        <li className="breadcrumb-item"><Link className="breadcrumb-link" to="/fireandsafety/outdoorburning">Outdoor Burning</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Burn Ban</li>
                    </ol>
                </nav>
                <ScrollToTopOnMount />
                <BurnBanDynamic />
            </div>
        </div>
    );
};

export default BurnBan;