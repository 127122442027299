import React from 'react';
// import FireAndSafetyIntro from './FireAndSafetyIntro';
import { Link } from "react-router-dom";
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import Breadcrumbs from './Breadcrumbs';

function SmokeDetectorTips() {
    return (
        <div id="smoke-detector-tips" className="content">
            <div className="container">
                <Breadcrumbs activePage="Smoke Detector Tips" />
                <ScrollToTopOnMount />
                {/* <FireAndSafetyIntro /> */}
                <h2 className="mt-4">Smoke Detector Tips</h2>
                <div className="row">
                    <div className="col-lg-9 col-sm-12">
                        <h3>Learning Objectives</h3>
                        <ol className="keep-as-list">
                            <li>Identify the purpose of smoke alarms.</li>
                            <li>Identify where smoke alarms should be installed.</li>
                            <li>Identify how to keep smoke alarms in working order.</li>
                        </ol>

                        <h3>Materials:</h3>
                        <ul className="keep-as-list mt-3">
                            <li>Smoke alarm</li>
                            <li>Batteries</li>
                        </ul>

                        <h3>Procedure:</h3>
                        <ol className="keep-as-list">
                            <li>Ask children if they know what smoke alarms are and why they should have them in their homes.</li>
                            <ul className="keep-as-list mt-3">
                                <li>Smoke alarms help protect families by making a very loud beeping noise to warn that smoke is in the air or a fire has started.</li>
                                <li>Sometimes, especially at night when people are sleeping, they may not see fire, smell smoke or wake up in time to get out safely.</li>
                                <li>Smoke alarms provide an early warning signal for escape from fire.</li>
                            </ul>
                            <li>Demonstrate what a smoke alarm looks like and the sound it makes when it detects smoke.</li>
                            <li>Ask children what they should do if they hear a smoke alarm sound.</li>
                            <ul className="keep-as-list mt-3">
                                <li>They need to follow their home fire escape plan and get out fast.</li>
                            </ul>
                            <li>Ask children if they know where smoke alarms should be installed.</li>
                            <ul className="keep-as-list mt-3">
                                <li>Smoke alarms should be installed on every level of the home, including the basement.</li>
                                <li>For extra safety, install smoke alarms both inside and outside the sleeping area.</li>
                                <li>Smoke alarms should be installed on the ceiling or on the side walls 6 to 8 inches below the ceiling.</li>
                            </ul>

                            <li>Ask children how often they think the batteries in smoke alarms should be changed.</li>
                            <ul className="keep-as-list mt-3">
                                <li>Batteries should be changed at least once a year and tested monthly to make sure they are working.</li>
                            </ul>
                            <li>Show children where the batteries go in a smoke alarm.</li>
                            <ul className="keep-as-list mt-3">
                                <li>Press the test button to demonstrate it is working properly. Also, remind children that alarms need to be kept clean from dust. This can be done by running a vacuum cleaner attachment over and around them.</li>
                            </ul>
                            <li>Conclude the lesson by telling students they can keep their homes safe from fire by helping grown-ups remember to:</li>
                            <ul className="keep-as-list mt-3">
                                <li>put smoke alarms in the home, especially near bedrooms</li>
                                <li>test smoke alarms monthly to make sure they are working</li>
                                <li>replace with brand new batteries at least once a year</li>
                                <li>keep smoke alarms clean from dust</li>
                            </ul>
                        </ol>
                        <h3>Smoke Alarm Safety Check for Parents</h3>
                        <article>Smoke alarms are very easy to install and take care of. To help teach your children about smoke alarms, ask them to help you install and maintain them.</article>

                        <ul className="keep-as-list mt-3">
                            <li>Install smoke alarms on every level of your home, including the basement.</li>
                            <li>Many fatal fires begin late at night or in the early morning. For extra safety, install smoke alarms both inside and outside the sleeping area.</li>
                            <li>Also, smoke alarms should be installed on the ceiling or 6 to 8 inches below the ceiling on side walls. Since smoke and many deadly gases rise, installing your smoke alarms at the proper level will provide you with the earliest warning possible.</li>
                            <li>Always follow the manufacturer's installation instructions.</li>
                            <li>Each month, ask your child to help you test all of the alarms in the home. This would also be a good time to make sure your alarms are clean and free of dust.</li>
                            <li>Ask your child to pick at least one special day a year, like a birthday, holiday or other special event. Designate that day as "Smoke Alarm Safety Day" and replace all of the batteries in your smoke alarms with new ones. If your home has "hard-wired" alarms (connected to the household electrical system), they may or may not have battery back-up.</li>
                        </ul>

                        <h5>Resources:</h5>

                        <ul className="keep-as-list mt-3">
                            <li><Link to="/fireandsafety/smokealarms">Smoke Alarms: What You Need to Know</Link></li>
                        </ul>
                    </div>
                    <div id="images" className="col-lg-3 col-sm-12">
                        <figure className="figure">
                            <img src={process.env.PUBLIC_URL + `/images/fire_and_safety_smoke_alarm.jpg`} className="figure-img img-fluid rounded border shadow-sm" alt="" />
                        </figure>
                        <figure className="figure">
                            <img src={process.env.PUBLIC_URL + `/images/fire_and_safety_smoke_alarms.jpg`} className="figure-img img-fluid rounded border shadow-sm" alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SmokeDetectorTips;