import React from 'react';

const SocialDistancing = () => {
    return (
        <>
            <h3>Practice Social Distancing</h3>

            <article className="mt-2 mb-2">Social distancing means keeping extra space between yourself and others to reduce the risk of getting or spreading COVID-19. If social distancing is done correctly it will help limit your exposure possibilities and slow the transmission of the virus from person to person. With the participation of everyone it will help avoid overwhelming our medical system. As a community lets work hard to protect ourselves and our loved ones.</article>

            <ul className="keep-as-list mt-3">
                <li>Stay home except for essential needs: medical appts, medication, groceries, or emergencies</li>
                <li>Keep at least 6 feet of space between yourself and others whenever possible.</li>
                <li>Wash your hands regularly, before and after an essential public activity.</li>
                <li>Help family and friends who need assistance staying home: Call and check on your neighbor.</li>
                <li>One-person go to the grocery store when needed to limit exposure possibilities. Take a list to help limit your visits.</li>
                <li>Clean your Fruits and Vegetables before placing in refrigerator or stored location.</li>
                <li>Avoid touching your face, mouth, nose and eyes.</li>
                <li>Clean — and then disinfect — surfaces and things you touch often. These include tables, chairs, doorknobs, light switches, buttons, handrails, countertops, remote controls, shared electronic equipment, shared exercise equipment, handles, desks, phones, keyboards, toilets, faucets and sinks.</li>
                <li>Promote outdoor activities at home to get that fresh air. Stay home does not mean stay inside.</li>
                <li>Explain to youth the importance of social distancing and preventing the spread of COVID-19</li>
            </ul>

            <div className="mt-3">
                <a href="https://medium.com/wadepthealth/social-distancing-and-quarantine-what-you-need-to-know-80b36f5bcc70" target="_blank" rel="noopener noreferrer">Read more about Social Distancing and Quarantine from the WA State DOH</a>
            </div>
        </>
    );
};

export default SocialDistancing;
