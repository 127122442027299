import React from 'react';
// import FireAndSafetyIntro from './FireAndSafetyIntro';
import Phone from '../Phone';
import ScrollToTopOnMount from '../../ScrollToTopOnMount';
import Breadcrumbs from './Breadcrumbs';
import BurnBanAlert from './BurnBanAlert';
// import { Link } from "react-router-dom";

function OutdoorBurning() {
    return (
        <div id="outdoor-burning" className="content">
            <div className="container">
                <Breadcrumbs activePage="Outdoor Burning" />
                <ScrollToTopOnMount />
                {/* <FireAndSafetyIntro /> */}
                {/* INSTRUCTIONS: set isLifted={false} if restrictions are in effect, remove if not */}
                <BurnBanAlert isLifted displayMoreInfo />
                <h2 className="mt-4">Outdoor Burning Regulations</h2>
                <div className="row g-0">
                    <div className="col-lg-9 col-sm-12">
                        <article className="mb-2 ms-2">
                            <div className="mb-2">
                                <strong>Mason County Fire Marshal Office</strong><br />
                            Mason County Bldg. III<br />
                            426 W Cedar / PO Box 186<br />
                            Shelton, WA 98584
                        </div>

                            <div className="mb-2">
                                <strong>Fire Marshal Larry Waters</strong><br />
                            Phone: <Phone number="(360) 427-9670 ext. 352" /><br />
                            </div>

                            <div className="mb-2">
                                <strong>Burn Ban Hotline</strong>: <Phone number="(360) 427-7799" />
                            </div>

                            <div className="mb-2">
                                Outdoor burning in Mason County, except burning on lands under the jurisdiction of a state or federal agency or a local air quality authority is subject to the requirements outlined here.
                        </div>

                            <div className="mb-3">
                                If you cannot find the answers to your questions below, you may call the Fire Marshal's Office at the numbers listed above.
                        </div>
                        </article>

                        <h5>Why worry about outside burning?</h5>

                        <article>
                            <article className="mb-3">
                                Smoke from outdoor burning is harmful to human health. The pollution that is generated from outdoor burning poses a serious threat to residents throughout the counties served by Olympic Region Clean Air Agency (ORCAA): Clallam, Grays Harbor, Jefferson, Mason, Pacific, and Thurston. Smoke from outdoor burning includes fine particulate matter that is especially harmful to babies and children whose developing lungs are small and vulnerable. Smoke also harms the elderly and those who suffer from heart disease, asthma, emphysema, or other respiratory diseases.
                        </article>
                            <article className="mb-3">
                                Smoke resulting from outdoor burning is also made up of a complex mixture of compounds. Many are toxic and are known to cause cancer.
                        </article>
                            <article className="mb-3">
                                Pollution from outdoor burning is also a threat to our environment. Outdoor burning has already been phased out in many urban areas of our state.
                        </article>
                            <article className="mb-4">
                                For a list of alternatives to burning in your area please contact ORCAA or see the agency web site <a href="http://www.orcaa.org" target="_blank" rel="noopener noreferrer">www.orcaa.org <i className="fa fa-external-link" aria-hidden="true"></i></a>.
                        </article>
                        </article>

                        <div className="card mb-4">
                            <div className="card-header">
                                Definitions
                        </div>
                            <div className="card-body">
                                <h5 className="card-title">Agricultural Burning</h5>
                                <p className="card-text">Is the burning of vegetative debris from an agricultural operation necessary for disease or pest control, crop propagation, and/or crop rotation, or where identified as a best management practice by an authoritatve source on agricultural practice.</p>

                                <h5 className="card-title">Construction / Demolition Debris</h5>
                                <p className="card-text">Means all material resulting from the construction, renovation, or demolition of buildings, roads, and other man-made structures.</p>

                                <h5 className="card-title">Native American Ceremonial Fires</h5>
                                <p className="card-text">Means fires necessary for Native American ceremonies (i.e., conducted by and for Native Americans) if part of a religious ritual. Ceremonial fires must follow all fo the guidelines for recreational fires.</p>

                                <h5 className="card-title">Land Clearing Burning</h5>
                                <p className="card-text">Means outdoor burning of trees, stumps, shrubbery, or other natural vegetation from land clearing projects (i.e., projects that clear the land surface so it can be developed, used for a different purpose, or left unused).</p>

                                <h5 className="card-title">Natural Vegetation</h5>
                                <p className="card-text">Means unprocessed plant material from herbs, shrubbery, and trees, including grass, weeds, leaves, clippings, prunings, brush, branches, roots.</p>

                                <h5 className="card-title">Outdoor Burning</h5>
                                <p className="card-text">Means the combustion of any type in an open fire or in an outdoor container without providing for the control of combustion or the control of emissions from the combustion. For the purposes of this rule, "outdoor burning" means all types of outdoor burning except agricultural burning and silvicultural burning.</p>

                                <h5 className="card-title">Residential Burning</h5>
                                <p className="card-text">Means the outdoor burning of leaves, clippings, prunnings and other natural vegetation yard and gardening refuse by the property owner or their designee.</p>

                                <h5 className="card-title">Recreational Fire</h5>
                                <p className="card-text">Means cooking fires, campfires, and bonfires using charcoal or firewood that occur in designated areas or on private property for cooking, pleasure, or ceremonial purposes. Recreational fires are not to be used for debris disposal.</p>

                                <h5 className="card-title">Smoke Management Zones</h5>
                                <p className="card-text">Are areas where land clearing burning is not allowed (burn permits cannot be issued) because of the population density and the public health impact of smoke from large fires. Residential yard debris fires and recreational fires are allowed in smoke management zones.</p>
                            </div>
                        </div>

                        <h5>Burn barrels are illegal for any form of outdoor burning</h5>

                        <article className="mb-3">
                            <ul className="keep-as-list mt-3">
                                <li>The Washington Clean Air Act states that only natural vegetation is allowed to be disposed of by outdoor burning. The following materials <u>may not be burned</u> in any outdoor fire: Garbage, dead animals, asphalt, petroleum products, paints, rubber products, plastics, paper (other than what is necessary to start a fire), cardboard, treated wood, construction/demolition debris, metal, or any substance (other than natural vegetation) that normally releases toxic emissions, dense smoke, or obnoxious odors when burned.</li>
                                <li>The Mason County Fire Marshal is authorized to prohibit all outdoor burning in the case of an extreme fire danger or an air pollution episode.</li>
                                <li>The person in charge fo the fire will be liable for the cost of suppression if the fire gets out of control or if becomes necessary to extinguish the fire as the result of an enforcement action.</li>
                                <li>Burn only during periods of calm to light winds. Burning during periods when wind may scatter loose, flammable materials is an extreme fire hazard.</li>
                            </ul>
                        </article>

                        <h5>Residential Burning</h5>

                        <article className="mb-3">

                            <ol>
                                <li>A written permit is not required for residential burning.
                        Maximum pile size is four (4) feet in diameter.</li>
                                <li>A minimum 10-foot firebreak must be constructed around each pile with a 20-foot vertical clearance from overhanging branches.</li>
                                <li>Only one fire at a time per parcel.</li>
                                <li>Burn pile must be at least 25 feet from any structure or combustible material. Materials to be burned shall not be moved from one site to another. All material must originate from the property on which the burning is to occur.</li>
                                <li>The fire must not create a nuisance from smoke, obscure visibility on public roads and highways, or endanger life and property.</li>
                                <li>No residential fire is to be commenced before dawn and no material is to be added after dusk.</li>
                                <li>A person and equipment capable of extinguishing the fire must be in constant attendance at the site while burning.</li>
                            </ol>
                        </article>

                        <h5>Land Clearing Burning / Agricultural Burning</h5>

                        <article className="mb-3">
                            <ol>
                                <li>A written permit is required for land clearing / agricultural burning.</li>
                                <li>Land clearing / agricultural burn permits are issued by Mason County Fire Marshal Larry Waters, and require a site inspection. Call <Phone number="(360) 427-9670 ext. 352" /> to make an appointment for a site inspection.</li>
                                <li>A valid permit must be on site while burning.</li>
                                <li>Materials to be burned must originate from the property on which the burning is to occur.</li>
                                <li>The fire must not create a nuisance from smoke, obscure visibility on public roads and highways, or endanger life and property.</li>
                                <li>Maximum pile size is twenty (20) feet in diameter.</li>
                                <li>A minimum 10-foot firebreak must be constructed around each pile.</li>
                                <li>Each pile must be at least 100 feet from any structure, at least 50 feet from standing timber.</li>
                                <li>No land clearing fire is to be commenced before dawn and no material is to be added after dusk.</li>
                                <li>A person and equipment capable of extinguishing the fire must be in constant attendance at the site while burning.</li>
                            </ol>

                        </article>

                        <h5>Recreational Burning</h5>

                        <article className="mb-3">
                            <ol>
                                <li>A written permit is not required for a recreational fire.</li>
                                <li>Recreational fires are <u>not to be used for debris disposal</u>.</li>
                                <li>Maximum fire size is three (3) feet in diameter.</li>
                                <li>A minimum 10-foot firebreak must be constructed around each fire with a 20-foot vertical clearance from overhanging branches.</li>
                                <li>Fire shall be in a metal or concrete fire pit, such as those typically found in designated campgrounds.</li>
                                <li>Only one fire at a time per parcel.</li>
                                <li>Fire must be at least 25 feet from any structure or combustible material.</li>
                                <li>The fire must not create a nuisance from smoke, obscure visibility on public roads and highways, or endanger life and property.</li>
                                <li>A person and equipment capable of extinguishing the fire must be in constant attendance at the site while burning.</li>
                            </ol>

                        </article>

                        <h5>Alternatives To Burning</h5>

                        <article className="mb-3">
                            Composting, chipping and recycling are viable alternatives to outdoor burning that create a resource of air pollution and a solid waste problem.
                    </article>

                        <article className="mb-3">
                            Composting is an easy and inexpensive way to turn yard and gardening refuse into a useable and valuable product. Compost turns organic matter, like leaves, weeds, and wood chips, into a rich soil conditioner.
                    </article>

                        <article className="mb-3">
                            Chipping brush, prunings, land-clearing debris and wood waste makes an excellent mulch or substitute for decorative bark. Chips can protect soil and help keep weeds at bay. They can also be used to line garden paths and trails or provide a softer landing under playground equipment.
                    </article>

                        <article className="mb-3">
                            Recycling / Reuse. Many options are available in our area for wood and building scraps,pallets, cardboard and other paper products, and even some types of plastic. Contact <b>Mason County's Solid Waste department</b> at <Phone number="(360) 427-9670 ext. 271" /> or a recycling coordinator for options in your area.
                    </article>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        {/* <figure className="figure">
                        <img src={process.env.PUBLIC_URL + `/images/fire_and_safety_bedroom.gif`} className="figure-img img-fluid rounded border" alt="" />
                        <figcaption className="figure-caption text-end">TBD</figcaption>
                    </figure>
                    <figure className="figure">
                        <img src={process.env.PUBLIC_URL + `/images/fire_and_safety_bedroom.gif`} className="figure-img img-fluid rounded border" alt="" />
                        <figcaption className="figure-caption text-end">TBD</figcaption>
                    </figure>
                    <figure className="figure">
                        <img src={process.env.PUBLIC_URL + `/images/fire_and_safety_livingroom.gif`} className="figure-img img-fluid rounded border" alt="TBD" />
                        <figcaption className="figure-caption text-end">TBD</figcaption>
                    </figure> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OutdoorBurning;