import React from "react";

function FireChiefCommissioners() {
  return (
    <div id="commissioners" className="content">
      <div className="container">
        <h2 className="page-heading mt-4">Commissioners</h2>

        <p className="p-5">
          As of July 1, 2024, Mason County Fire District 3 merged into Central
          Mason Fire & EMS. For information about Central Mason Board of
          Commissioner, please visit our website at 
          <a
            href="https://cmfe.org/commissioners"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://cmfe.org/commissioners
          </a>.
            Commissioner Grauer has decided to retire effective July 1st. We
          would like to thank him for his dedication to our Fire District and
          years of service.
        </p>
        {/* <h4 className="mt-4">Position 1 - Randy Grauer</h4>
        <h6 className="mb-4">Current term: 2016 through 2021</h6>

        <p>
          COMMISSIONER GRAUER was first elected in November, 2015 and re-elected
          in November, 2021.
        </p>
        <p>
          Grauer has owned property in Grapeview since 1982. He and his wife
          built their retirement home here in 1991. They have lived here full
          time since 2008.
        </p>
        <p>
          He graduated from college with a B.A. in 1965 and from seminary in
          1969. He served as pastor of Lutheran Churches in St. Louis, MO,
          Renton, Bellevue, and Bellingham for nearly 40 years until his
          retirement in 2008.
        </p>
        <p>
          Randy has extensive community experience in the cities where he
          served, where he volunteered for many years on non-profit boards and
          community organizations.
        </p>

        <h4 className="mt-4">Position 2 - Nancy Montgomery</h4>
        <h6 className="mb-4">Current Term: 2012 through 2023</h6>

        <p>
          Nancy has been a property owner in Grapeview since 1980 and she and
          her family have been full time residents since 1996. She has been
          actively involved in community service and spent over 30 years in
          Administrative Management and Customer Service with insurance
          companies, insurance brokerage firms and insurance agencies and was
          licensed as an agent in the Commonwealth of Pennsylvania and the State
          of Washington for Property/Casualty and Life & Health.
        </p>

        <p>
          Nancy was first elected as commissioner in November 2011. Her current
          term is through December 2023. She has served as Chair since 2017.
        </p>

        <h4 className="mt-4">Position 3 - Mike Creighton</h4>
        <h6 className="mb-4">Current Term: 2020 through 2025</h6>

        <p>
          Mike has served as a Mason Fire District 3 Commissioner since December
          2011 and as Commission Chair from January 2012 to December 2016. His
          family has owned property in Mason County since 1968 and he and his
          wife have owned a home in Grapeview since 2007.
        </p>

        <p>
          Mike graduated from the UW with a degree in Business, served in the US
          Army, and spent his professional career in the insurance industry as
          an underwriter and broker.
        </p>

        <p>
          Mike has been involved in community service most of his adult life. He
          has 20+ years of non-profit board experience as well as 20+ years
          experience as an elected local official serving on a school board,
          city council and as a fire commissioner.
        </p> */}
      </div>
    </div>
  );
}

export default FireChiefCommissioners;
